
import React, { useEffect, useState } from "react";
import "./Header.css";
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
 
import { useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
 
import axios from "axios";
 
const Header = () => {
  const navigate = useNavigate();
  const [showDropdowns, setShowDropdowns] = useState(false);
 
  const { accounts } = useMsal();
  const user = accounts[0] || {};
  const usermail = user.username
  const accesspeople = [
    "Sai.PVN@swissport.com",
    "Punam.Gaikwad@swissport.com",
    "Debaruna.Mitra@swissport.com",
    "Priyanka.Titarmare@swissport.com",
    "Sohini.Mitra@swissport.com",
    "Amit.Kapoor@swissport.com",
    "Litty.Thomas@swissport.com"
  ]
  console.log("All small case user email",usermail)
 
  const token = sessionStorage.getItem("swiss.api.access_token");
  const baseUrl = sessionStorage.getItem("swiss.api.baseUrl");
  const emailcheck = async () => {
    const data = JSON.stringify({ email: usermail });
    const config = {
      method: "post",
      url: `${baseUrl}/api/validateemail?aa=bb`,
      headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${token}`,
      },
      data,
    };

    try {
      const response = await axios.request(config);
      console.log("Data in validating email address", response.data);
      console.log("Type of jobtitle", typeof(response.data))
      //console.log("email address", response.data.isAuthorized);

      // Assuming the response data contains a boolean or a condition for authorized emails
      if (response.data === "Helpdesk SME" || response.data === "Helpdesk Team Lead" || response.data === "Helpdesk Quality Analyst" || accesspeople.includes(usermail)) {
        console.log("You have access")
        setShowDropdowns(true);
      } else { 
        console.log("You don't have access")
        setShowDropdowns(false);
      }
    } catch (error) {
      console.error("Error while validating user email address in Header.`js`:", error);
      setShowDropdowns(false); // Default to hiding the dropdown
    }
  };
  useEffect( () => {
    //setShowDropdowns(true);
    emailcheck()
 
  
  }, [usermail]);

  const handleRedirect = () => {
    console.log("redirectinggggggg")
    navigate('/generate-report');
  };
 
  return (
    <header className="chat-header">
      <div>
        <img
          className="bot-img"
          src={require("../assets/SP_Logo_white_cmyk.png")}
          alt="bot logo"
        />
      </div>
      <div className="chat-header-swiss">
        <span className="head">IT Helpdesk Chat</span>
      </div>
      {showDropdowns && (
        <button
          onClick={handleRedirect}
          className="redirect-button"
          title="generate report"
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" size = "2x" />
        </button>
      )}
    </header>
  );
  
};
 
export default Header;

/*
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

function HomePage() {
  const [showDropdowns, setShowDropdowns] = useState(false);
  const navigate = useNavigate();
  setShowDropdowns(true)
  const handleNavigate = () => {
    navigate('/generate-report'); // Correct way to navigate
  };

  return (
  
      <header className="chat-header">
          <div>
        <button
          onClick={handleNavigate}
          className="redirect-button"
          title="generate report"
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
        </button>
    </div>
     </header >
  );
}

export default HomePage;
*/
