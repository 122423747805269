
//import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReportPage from './components/ReportPage';

import React, { useState, useEffect } from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   useNavigate,
// } from "react-router-dom";
import Chatbot from "./components/Chatbot";
import Header from "./components/Header";
import "./App.css";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import Login from "./Login";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import * as microsoftTeams from "@microsoft/teams-js";
import { acquireAccessToken, acquireUserProfile } from "./authUtil";
;

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const [sso, setSso] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const isInTeams = queryParams.get("msteams") === "true";
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""
    }`;
  sessionStorage.setItem("swiss.api.baseUrl", baseUrl);

  useEffect(() => {
    if (isInTeams) {
      console.log("Inside Teams SSO updated");
      try {
        microsoftTeams.initialize();
        microsoftTeams.app.initialize();
        microsoftTeams.appInitialization.notifyAppLoaded();
        microsoftTeams.appInitialization.notifySuccess();

        // Get Teams Context
        microsoftTeams.getContext(() => {
          const authTokenRequest = {
            successCallback: async (result) => {
              try {
                let data = await acquireAccessToken(result);
                let profile = await acquireUserProfile(data.access_token);
                setSso(true); // Teams SSO Successful
              } catch (err) {
                console.error("Error acquiring token or user profile: ", err);
              }
            },
            failureCallback: (err) => {
              console.error("Teams SSO Failed: ", err);
            },
          };
          microsoftTeams.authentication.getAuthToken(authTokenRequest);
        });
      } catch (error) {
        console.error("Teams SSO Initialization Error: ", error);
      }
    }
  }, [isInTeams]); // Empty dependency array ensures this runs once on mount

  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isInTeams ? (
                <>
                  <div className="App">
                    <Header />
                    <div className="warning-message">
                      <span>
                        Warning: Please note for Chat in MS Teams Apps, If you
                        click away from this chat, it will end your session...
                      </span>
                    </div>
                    <div className="app-container">
                      {sso ? (
                        <>
                          <Chatbot />
                        </>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <MsalProvider instance={msalInstance}>
                    <AuthenticatedTemplate>
                      <div className="App">
                        <Header />
                        <div className="app-container">
                          <Chatbot />
                        </div>
                      </div>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <Login />
                    </UnauthenticatedTemplate>
                  </MsalProvider>
                </>
              )
            }
          />
          <Route
            path="/generate-report"
            element={<MsalProvider instance={msalInstance}>
              <AuthenticatedTemplate>
                <ReportPage />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </MsalProvider>
            }
          />

        </Routes>
      </Router>
    </div>
  );
}

export default App;


/*
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReportPage from './components/ReportPage';
import HomePage from './components/Header';
 
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/generate-report" element={<ReportPage />} />
      </Routes>
    </Router>
  );
}
 
export default App;
*/