import React, { useState, useEffect, useCallback, useRef } from "react";
import { TextField, Button, Paper, List, ListItem, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import LanguageSelector from "./LanguageSelector";
import Message from "./Message";
import "./Chatbot.css";
import { ITServices } from "../usecases/Database/IT Category & Subcategory";
import Card from "./Card";
import { useMsal } from '@azure/msal-react';
import { ITServiceMapping } from "../usecases/Database/NumberMapping";
import { RequestedItemMapping } from "../usecases/Database/NumberMapping";
import { userDetails } from "../usecases/Halo/getUserDetails";
import { v4 as uuidv4 } from 'uuid';
import TKTCard from "./ticketcard";
import axios from "axios";
import { acquireAccessToken, acquireUserProfile, refreshAccessToken } from "../authUtil";
import getRedisConvo from '../utils/getRedisConvo';
import updateRedis from '../utils/updateRedis';
import config from "../utils/config";
import Excellent from "../assets/img/Excellent.png";
import Good from "../assets/img/Good.png";
import OK from "../assets/img/OK.png";
import Poor from "../assets/img/Poor.png";
import { type } from "os";
var dbdocdbid
var filestate
var attachmentsizestate
var uploadedstate
var transuploadfilebutton
var nameoffile
var inputtostring
var sessionconfig
var changelanstate
var disconnection
var timeout
var articlelist = []
var listofkb
var transsubemail
var transfdback
var transyesreply
var transnoreply
var convdatasofar
var datasofar
var csat
var csatstate
var kbarticlehelpedtrans
var dbagentfeedback
var dblast5ticketsfeedback
var dbsearchwithticketfeedback
var dbkbarticlefeedback
var dbcreateticketfeedback
var dbagentrating
var dblast5ticketsrating
var dbsearchwithticketrating
var dbkbarticlerating
var dbcreateticketrating
var dbliveagent
var dbkbticket
var dbnormalticket
var dblanguage
var dbemail
var dbtimestamp
var dbagentname
var dbkblist = []
var starttime
var dbstarttime
var dbjustdate
var selectedKbArticle
var Lan
var transcliveagent
var translatedKBArticles
var translatedTexts
var transmailmsg
var makerequest;
var raiseaticketbutton;
var transraiseaticketbutton
var exitchat;
var searchwithtktnumber;
var showmoretktdetails;
var liveagent;
var mainmenu;
var last5tkt;
var userInfo;
var textarea;
let message;
var summary;
var iTservice;
var requestItem;
var impactedUser;
var otheruserID;
var ticketnum;
var ticketdata;
var selectedITservice;
var selectedRequestItem;
var newSessionId;
var count = 0;
var datacount = 0;
const conversationHistory = [];
var convo;
var agentgenesys
var convsdata;
let ws = null;
var menumain;
var userName
var agentName
var hasExecuted = false;
var openKBarticle = [];
var preSignedURL = [];
var submitbutton
var reviewMessage = 'Please review the Knowledge based articles and let me know if you found them helpful?';
var translatedReviewMessage;
const useStyles = makeStyles({
  messageList: {
    flexGrow: 1,
    overflowY: "auto",
  },
  inputArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  input: {
    flexGrow: 1,
    marginRight: "10px",
  },
});
var transrequestraise;

const Chatbot = () => {
  const { accounts } = useMsal();
  const [auth, setAuth] = useState(false);
  let sso = {};
  try {
    sso = JSON.parse(sessionStorage.getItem('swiss.sso'));
  } catch (err) {
    console.log("Error in SSO in Chatbot.js file")
  }
  useEffect(() => {
    const initTeamsSSO = async () => {
      if (accounts[0]) {
        // console.log("accounts[0].idToken", accounts[0].idToken)
        if (!sessionStorage.getItem('swiss.api.access_token')) {
          await acquireAccessToken(accounts[0].idToken).then((value) => {
            // console.log("============================response0data1:", value);
            let profile = acquireUserProfile(value.access_token);
            setAuth(true);
          })
        } else {
          setAuth(true);
        }
      }
    }
    initTeamsSSO();
  }, [auth]);

  const baseUrl = sessionStorage.getItem('swiss.api.baseUrl');
  const user = accounts[0] || sso;
  // console.log("-------user:", user);
  //fetching token from sessionStorage

  // console.log("token value in component::", token);
  const [file, setFile] = useState(false);
  const [menuafterticketdetails, setmenuafterticketdetails] = useState(null);
  const [buttonmenuafterticketdetails, setbuttonmenuafterticketdetails] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [inputticket, setInputticketnum] = useState("");
  const [language, setLanguage] = useState(null);
  const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);
  const [translatedOptions, setTranslatedOptions] = useState({});
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [extraButtonsVisible, setExtraButtonsVisible] = useState(false);
  const [awaitingDescription, setAwaitingDescription] = useState(false);
  const [pendingOption, setPendingOption] = useState("");
  const [noKbButtonsVisible, setNoKbButtonsVisible] = useState(false);
  const [createIncidentsVisible, setcreateIncidentsVisible] = useState(false);
  const [createIncidentsVisibleTranslations, setcreateIncidentsVisibleTranslations] = useState({});
  const [noKbButtonTranslations, setNoKbButtonTranslations] = useState({});
  const [myTicketsExtraButtonsVisible, setMyTicketsExtraButtonsVisible] = useState(false); // State for "My Tickets" extra buttons
  const [myTicketsButtonTranslations, setMyTicketsButtonTranslations] = useState({}); // State for "My Tickets" button translations
  const [translatedAssistanceMessage, setTranslatedAssistanceMessage] = useState("");
  const [selfDropdownVisible, setSelfDropdownVisible] = useState(false);
  const [showSevenOptions, setShowSevenOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [showSubcategories, setShowSubcategories] = useState(false);
  const [showApplicationList, setShowApplicationList] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showMainMenuExitButtons, setShowMainMenuExitButtons] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [mainmenubutton, setmainmenu] = useState(true);
  const [exitchatbutton, setexitchat] = useState(false);
  const [backtotickets, setbacktotickets] = useState(false);
  const [searchwithtktnum, setsearchwithtktnum] = useState(false);
  const [showMoreTicketDetailsButton, setShowMoreTicketDetailsButton] = useState(false);
  const [kblink, setkblink] = useState(false);
  const [otherButtonsVisible, setOtherButtonsVisible] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isTicketForm, setIsTicketForm] = useState(false);
  const [chatliveagent, setchatliveagent] = useState(false);
  const [kbYesNO, setkbYesNo] = useState(false);
  const [kbYesNOTranslations, setkbYesNoTranslations] = useState({});
  const [kbYesNO1, setkbYesNo1] = useState(false);
  const [kbYesNOTranslations1, setkbYesNoTranslations1] = useState({});
  const [sessionId, setSessionId] = useState(null);
  const [showKBarticleButtons, setShowKBarticleButtons] = useState(true);
  const [userSelection, setUserSelection] = useState(null);
  const [raiseRequestButtonVisible, setRaiseRequestButtonVisible] = useState(false);
  const [isinputEnabled, setIsInputEnabled] = useState(false);
  const [translatedKBArticleButtons, setTranslatedKBArticleButtons] = useState([]);
  const classes = useStyles();
  const [languageKb, setLanguageKb] = useState('en');
  const [KBhelpList, setKBhelpList] = useState([]);
  const [showKBhelpList, setShowKBhelpList] = useState(false);
  const [IsVisible, setIsVisible] = useState(false);
  const [IsFile, setIsFile] = useState(false);
  var fileready = false
  var botMessage;
  var url;
  let isConfigured = false;
  var langauge;
  const queryParams = new URLSearchParams(window.location.search);
  const isInTeams = queryParams.get("msteams") === "true";
  var token = sessionStorage.getItem('swiss.api.access_token');
  const [feedbackOptionsVisible, setFeedbackOptionsVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [detailedFeedback, setDetailedFeedback] = useState("");
  const [showFeedbackInGetTicketDetails, setShowFeedbackInGetTicketDetails] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);
  // const [isModalOpen, setModalOpen] = useState(false);
  // const fileInputRef = useRef(null);

  // Fetching token from session
  if (sessionStorage.getItem('swiss.api.access_token')) {
    const interval = setInterval(() => {
    const response =  refreshAccessToken();
  
      console.log("Refresh token call here in chatbot.js file", response);
    }, 3600000); // 60 minutes
  }

  const messageEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages, showMoreTicketDetailsButton, showMainMenuExitButtons, otherButtonsVisible, kbYesNO, kbYesNO1, noKbButtonsVisible, createIncidentsVisible, myTicketsExtraButtonsVisible, menuafterticketdetails, tickets, buttonmenuafterticketdetails, showAdditionalButtons, showEmailInput, showKBhelpList, setFeedbackOptionsVisible, showConfirmation, file, IsFile]);

  // showFeedbackOptions function will set the rating buttons in the chatbot
  const showFeedbackOptions = async () => {
    try{
    const feedbackMessage = 'If you could please take a moment to rate your experience with the Swissport IT support team, by clicking below';
    const translatedFeedbackMessage = await translateEnglishToOther(feedbackMessage, language);
    addDialog(feedbackMessage, true)
    await updatedb()
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: translatedFeedbackMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" }
    ]);
    setFeedbackOptionsVisible(true);
  }
  catch(error)
  {
    console.log("Error in showFeedbackOptions function in chatbot.js file", error)
  }
  };

  // formatConversation function will format the chat histroy with readable spaces for live agent and for KB article ticket
  async function formatConversation(convo) {
    let output = '';
    // Log the received input
    console.log("Inside format conversation function");
    console.log("Type of convo:", typeof convo);
    //console.log("Convo:", convo);

    // Convert the JSON string to an array
    try {
      convo = JSON.parse(convo);
      console.log("converted the json string to array", convo)
      console.log("in format", typeof (convo))
      convo = Object.entries(convo)
      console.log("type after converting to array", typeof (convo))
    } catch (error) {
      throw new TypeError("Failed to parse input string as JSON. Ensure it is a valid JSON array in formatConversation function in chatbot.js file");
    }

    // Check if the parsed input is an array
    if (!Array.isArray(convo)) {
      throw new TypeError("Expected input to be an array after parsing JSON.");
    }

    // Iterate through the outer array
    convo.forEach((subArray, index) => {
      // Check if each subArray is indeed an array
      if (!Array.isArray(subArray)) {
        console.error(`Element at index ${index} is not an array.`);
        throw new TypeError(`Each element of convo should be an array. Found at index ${index} in formatConversation function in chatbot.js file`);
      }

      // Ensure each item in the subArray is an object
      subArray.forEach((item, itemIndex) => {
        if (typeof item === 'object' && item !== null) {
          for (const [role, message] of Object.entries(item)) {
            console.log("AGENT NAME IN FORMAT CONVERSATION", dbagentname)
            console.log("USER NAME IN FORMAT CONVERSATION", user.name)
            if (role === 'Bot') {
              if (Array.isArray(message)) {
                output += `Bot: ${message.join(', ')}\n`;
              } else {
                output += `Bot: ${message}\n`;
              }
            } else if (role === user.name) {
              console.log("USER NAME IN FORMAT CONVERSATION INSIDE ELSE IF", user.name)
              output += `${user.name}: ${message}\n`;
            } else if (role === dbagentname) {
              console.log("AGENT NAME IN FORMAT CONVERSATION INSIDE ELSE IF", dbagentname)
              if (Array.isArray(message)) {
                output += `${dbagentname}: ${message.join(', ')}\n`;
              } else {
                output += `${dbagentname}: ${message}\n`;
              }
            }
          }
          // Add an extra newline after each message block for separation
          output += '\n';
        } else {
          console.warn(`Item at index ${itemIndex} is not an object:`, item);
        }
      });
    });

    return output.trim();
  }
 // validateSessionID function validates if the current session has the correct session id
  const validateSessionId = (newSessionId) => {
    try{
    //console.log('Current Session ID:', newSessionId);
    //console.log('Stored Session ID:', sessionId);
    return newSessionId === sessionId;
    }
    catch(error){
      console.log("Error in validateSessionId function in chatbot.js file", error)
    }
  };
  // generateSessionId function generates 32 length session id
  const generateSessionId = async () => {
    try{
    const uuid = uuidv4(); // Generate a UUID v4
    // Remove dashes to get a 32-character string
    const sessionId = uuid.replace(/-/g, '');

    return sessionId;
    }
    catch(error){
      console.log("Error in generateSessionId function in chatbot.js file", error)
    }
  };
  // addDialog function will be used to add dialogs said by Bot and User these dialogs will be updated in ConversationHistory variable
  // const addDialog = async (dialog, isAgentBot = true, userName = 'User', agentName = 'Agent') => {
  //   try {
  //     if (agentgenesys && filestate == false) {
  //       console.log('In addDialog if condition');
  //     // Use dynamic names for agent and user based on isAgentBot flag
  //     conversationHistory.push({ [isAgentBot ? agentName : userName]: dialog });
  //     // console.log('Dialog added:', { [isAgentBot ? agentName : userName]: dialog });
      
  //   } else {
  //     console.log('Session ID is valid');
  //     // Use dynamic names for bot and user based on isAgentBot flag
  //     conversationHistory.push({ [isAgentBot ? 'Bot' : userName]: dialog });
  //     // console.log('Dialog added:', { [isAgentBot ? 'Bot' : userName]: dialog });
  //     //console.log("Inside else condition in adddialog function")
  //   }
  // }
  // catch(error){
  //   console.log("Error in addDialog function in chatbot.js file", error)
  //   }
  // };
  const addDialog = async (dialog, isAgentBot = true, userName = 'User', agentName = 'Agent') => {
    try {


      const now = new Date();
      const utcHours = String(now.getUTCHours()).padStart(2, '0');
      const utcMinutes = String(now.getUTCMinutes()).padStart(2, '0');
      const timestamp = `${utcHours}:${utcMinutes}`;
   
      // Add timestamp to the dialog
      const dialogWithTimestamp = `(${timestamp}) : ${dialog}`;
      if (agentgenesys && filestate == false) {
        console.log('In addDialog if condition');
      // Use dynamic names for agent and user based on isAgentBot flag
      conversationHistory.push({ [isAgentBot ? agentName : userName]: dialogWithTimestamp });
      // console.log('Dialog added:', { [isAgentBot ? agentName : userName]: dialog });
      
    } else {
      console.log('Session ID is valid');
      // Use dynamic names for bot and user based on isAgentBot flag
      conversationHistory.push({ [isAgentBot ? 'Bot' : userName]: dialogWithTimestamp });
      // console.log('Dialog added:', { [isAgentBot ? 'Bot' : userName]: dialog });
      //console.log("Inside else condition in adddialog function")
    }
  }
  catch(error){
    console.log("Error in addDialog function in chatbot.js file", error)
    }
  };
 
// translateEnglishToOther function converts single English text to Other langauges
  const translateEnglishToOther = (txt, language) => {
    return new Promise(async (resolve, reject) => {
      let data = JSON.stringify({
        MSG: txt,
        code: language
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/api/translate?aa=bb`,
        headers: {
          'Content-Type': 'application/json',
          "authorization": `Bearer ${token}`
        },
        data: data
      };
      try {
        console.log("Language ------------001---002");
        const response = await axios.request(config)
        //console.log("Response", response)
        resolve(response.data)
      } catch (e) {
        console.error("Error in translateEnglishToOther function in chatbot.js file", e)
        reject({})
      }
    })
  }
  // translateOtherToEnglish function converts other language single text to English
  const translateOtherToEnglish = async (txt, language) => {
    let data = JSON.stringify({
      MSG: txt,
      code: language
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/translateothertoen?aa=bb`,
      headers: {
        'Content-Type': 'application/json',
        "authorization": `Bearer ${token}`
      },
      data: data
    };
    try{
    const response = await axios.request(config)
    //console.log("Response in other to en", response)
    return response.data
  }
    catch(error){
      console.log("Error in translateOtherToEnglish function in chatbot.js file", error)
    }
  }
// translateMultipleEnglishToOther function converst multiple English texts to Other
  const translateMultipleEnglishToOther = async (txt, language) => {
    return new Promise(async (resolve, reject) => {
      let data = {
        MSG: txt,
        code: language
      };

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/api/translatemultiple?aa=bb`,
        headers: {
          'Content-Type': 'application/json',
          "authorization": `Bearer ${token}`
        },
        data: data
      };
      try {
        const response = await axios.request(config)
        //console.log("Response mutiple", response)
        resolve(response.data)
      } catch (e) {
        reject({})
        console.error("Error in translateMultipleEnglishToOther function in chatbot.js file ", e)
      }
    })

  }

// updatedb function updates the user dialogs and other reports related information into AWS document DB
  const updatedb = async () => {
    let data = JSON.stringify({
      session: newSessionId,
      dbliveagent: dbliveagent,
      dbkbticket: dbkbticket,
      dbnormalticket: dbnormalticket,
      dblanguage: dblanguage,
      dbemail: dbemail,
      dbconv: conversationHistory,
      dbKBhelp: selectedKbArticle,
      dbstarttime: dbstarttime,
      dbjustdate: dbjustdate,
      dbtimestamp: dbtimestamp,
      dbagentname: dbagentname,
      dbagentrating: dbagentrating,
      dbagentfeedback: dbagentfeedback,
      dblast5ticketsrating: dblast5ticketsrating,
      dblast5ticketsfeedback: dblast5ticketsfeedback,
      dbsearchwithticketrating: dbsearchwithticketrating,
      dbsearchwithticketfeedback: dbsearchwithticketfeedback,
      dbkbarticlerating: dbkbarticlerating,
      dbkbarticlefeedback: dbkbarticlefeedback,
      dbcreateticketrating: dbcreateticketrating,
      dbcreateticketfeedback: dbcreateticketfeedback,
      dbkblist:openKBarticle,
      dbtimeout:timeout,
      dbdocdbid: dbdocdbid

    });
    //console.log("data so far inside update document db", data);
    //console.log("Data type", typeof (data))
    datasofar = JSON.parse(data)
    listofkb = datasofar.dbkblist
    convdatasofar = datasofar.dbconv
    //console.log("conversation", convdatasofar)
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/updatedb?aa=bb`,
      headers: {
        'Content-Type': 'application/json',
        "authorization": `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios.request(config)
      console.log("Data in update doc db function", response)
      return response.data

    }
    catch (error) {
      console.log("Error in update db function in chatbot.js file", error)
    }
  }
// KBhelpListClick function triggers when user says that KB article helped the user
  const KBhelpListClick = async (kbname) => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid in get ticket details');
    try {
      console.log("KB HELP LIST FUNCTION")
      if (KBhelpList.length === 0) {
        setkbYesNo(false);
        const yeskb1 = "Yes";
        const transyeskb1 =
          await translateEnglishToOther(
            yeskb1,
            language
          );
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: transyeskb1, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
        ]);
        addDialog(yeskb1, false, user.name)
        console.log("KBhelpList::::", KBhelpList);
        const help = "Do you need further help?"
        const transhelp = await translateEnglishToOther(
          help,
          language
        );
        addDialog(help, true)
        // await getConversationHistory();
        // await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        const yesNoOptions = ["Yes", "No"];
        const translatedYesNoOptions = await translateMultipleEnglishToOther(
          yesNoOptions,
          language
        );
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: transhelp, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
        ]);
        setkbYesNo1(true);
        setkbYesNoTranslations1(translatedYesNoOptions);
      }

      else {
        // openKBarticle = []
        articlelist = KBhelpList
        console.log("Opend kb article list", openKBarticle)
        console.log("KBhelpList Length", KBhelpList.length);
        console.log("KBhelpList List", KBhelpList);
        console.log("article list", articlelist)
        console.log("1st postion of kb article", KBhelpList[0] )
        setkbYesNo(false);

        const yeskb1 = "Yes";
        const transyeskb1 =
          await translateEnglishToOther(
            yeskb1,
            language
          );
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: transyeskb1, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
        ]);
        addDialog(yeskb1, false, user.name)
        const kbMsg = "Can you please select which Knowledge Base Article helped your query?"
        const kbMsgTrans = await translateEnglishToOther(
          kbMsg,
          language
        );
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: kbMsgTrans, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
        ]);
        addDialog(kbMsg, true)
        // await getConversationHistory();
        // await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        console.log("showing KB list which help user::");
        setShowKBhelpList(true);
      }
    } catch (err) {
      console.log("Error in KBhelpListClick function KBhelpListClick -- Error occured while showing list of KB articles which helped user ")
    }
  }

// kbclick function triggers after user selects which kb article helped the user
  const kbclick = useCallback(
    async (option) => {

      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      try {
     console.log(" KB CLICK FUNCTION")
        if (option === "NO") {
          console.log("Inside No::")
          setShowKBhelpList(false);
          setkblink(false);
          setkbYesNo1(false);
          setkbYesNo(false);
          setShowKBarticleButtons(false);
          const nokb = "No";
          const transnokb =
            await translateEnglishToOther(
              nokb,
              language
            );
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: transnokb, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
          ]);
          addDialog(nokb, false, user.name)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          raiseaticketbutton = "Raise a Ticket"
          transraiseaticketbutton = await translateEnglishToOther(
            raiseaticketbutton,
            language
          )
          setNoKbButtonsVisible(true);
          console.log("Language::::", language)
          console.log("Raise a ticket translatedt text", transraiseaticketbutton)
          setkblink(false);
          setkbYesNo1(false);
          setkbYesNo(false);
        }

        else {
          setShowKBhelpList(false);
          setShowKBarticleButtons(false);
          setkblink(false);
          setkbYesNo(false);
          //selectedKbArticle = option;

          console.log("option", option)
          //console.log("type of option variable", typeof(option))
          console.log("list of kb from db", listofkb)
          console.log("list of kb selecteddd", articlelist)
          var position = articlelist.indexOf(option)
          //console.log("position of selected article from the list of kb from db", position)
          selectedKbArticle =listofkb[position];
          console.log("selected KB article which help user to resolve the issue::", selectedKbArticle);
          // kbarticlehelpedtrans = await translateOtherToEnglish(
          //   selectedKbArticle,
          //   language

          // )
         
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();


          
          //console.log("KB article which help user to resolve the issue::", kbarticlehelpedtrans);
          const yeskb = option;
          const transyeskb =
            await translateEnglishToOther(
              yeskb,
              language
            );
          addDialog(yeskb, false, user.name)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: transyeskb, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
          ]);
          const help = "Do you need further help?"
          const transhelp = await translateEnglishToOther(
            help,
            language
          );
          addDialog(help, true)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          const yesNoOptions = ["Yes", "No"];
          const translatedYesNoOptions = await translateMultipleEnglishToOther(
            yesNoOptions,
            language
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: transhelp, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
          ]);
          setkbYesNo1(true);
          setkbYesNoTranslations1(translatedYesNoOptions);
        }

      } catch (error) {
        console.error("Error in kbclick function in chatbot.js file", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: `Sorry, there was an error processing your request.  Please close your browser window and start a new chat session`,
            createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
            from: "bot"
          },
        ]);
      }
    },
    [language]
  );
// kbclick1 function triggers when user presses yes or no when asked Do you need furthur help
  const kbclick1 = useCallback(
    async (option) => {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      setkbYesNo1(false);
      setkbYesNo(false);
      setkblink(false);
      try {
        console.log(" KB CLICK 111111 FUNCTION ")
        switch (option) {
          case "YES1":
            const yeskb = "Yes";
            const transyeskb =
              await translateEnglishToOther(
                yeskb,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transyeskb, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
            ]);
            addDialog(yeskb, false, user.name)
            // await getConversationHistory();
            // await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            await handleAgent();
            break;

          case "NO1":
            const nokb = "No";
            const transnokb =
              await translateEnglishToOther(
                nokb,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transnokb, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
            ]);
            addDialog(nokb, false, user.name)
            addDialog(user.username, true)
            //// await getConversationHistory();
            //// await updateRedis(conversationHistory, newSessionId, token);
            // convo = await getRedisConvo(newSessionId, token);
            await updatedb()
            console.log("type of data", typeof (convdatasofar))
            convsdata = await formatConversation(JSON.stringify(convdatasofar));
            console.log("Formated conversation", convsdata);
            console.log("type of convs data in live agent", typeof convsdata)
            userInfo = await userDetails(user.username, token);
            showFeedbackOptions(true);
            setFeedbackOptionsVisible(true);

            const response = await fetch(`${baseUrl}/api/resolveticketcreation?aa=bb`, {
              method: "POST",
              headers: {
                "authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                summary: summary,
                note: convsdata,
                mailID: user.username,
                ClientID: userInfo.clientID,
                SiteID: userInfo.siteID,
                UserID: userInfo.userID
              }),
            });
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            console.log("Data from frontend", data);
            // const message1 = "Thank you for chatting with us"
            // const translatedMessage1 = await translateEnglishToOther(
            //   message1,
            //   language
            // );
            // addDialog(message1, true)
            addDialog(user.username, true)
            // await getConversationHistory();
            // await updateRedis(conversationHistory, newSessionId, token)
            // setMessages((prevMessages) => [
            //   ...prevMessages,
            //   { text: translatedMessage1,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
            // ]);
            dbkbticket = JSON.stringify(data)
            console.log("Kbarticle ticket before updating in document db", dbkbticket)
            await updatedb();
          //setShowMainMenuExitButtons(true);
        }
      } catch (error) {
        console.error("Error in kbclick1 function in chatbot.js file", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: `Sorry, there was an error processing your request.  Please close your browser window and start a new chat session`,
            createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
            from: "bot"
          },
        ]);
      }
    },
    [language]
  );

  // getticketdetails function gets the Ticket details based on the ticket number entered by user, When user presses Search With Ticket number and enters ticket number thsi function will be triggered
  const getticketdetails = async () => {
    ///////////Function used when user enters ticket number manually
    csat = "searchwithticket"
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid in get ticket details');
    try {
      console.log("Docment id after selects search with ticket number", dbdocdbid);
      const translatedMessage = input;
      console.log("message::", translatedMessage);
      ticketnum = input;
      console.log("User selected ticket number when user presses Search with ticket number button", ticketnum);

      const isNumeric = async (input) => {
        // Check if input is of type number and is finite
        if (typeof input === 'number' && isFinite(input)) {
          return true;
        }
        // Check if input is a string and can be converted to a number
        if (typeof input === 'string' && !isNaN(input) && !isNaN(parseFloat(input))) {
          return true;
        }
        return false;
      }
      const status = await isNumeric(ticketnum);
      if (status === false) {
        count += 1;
        if (count < 3) {
          const entercorrecttktnumber = 'Please enter a correct ticket number, please make sure you enter numeric digits'
          console.log("User entered non numeric input", count)
          const translatedTextentercorrecttktnum = await translateEnglishToOther(entercorrecttktnumber, language);
          addDialog(entercorrecttktnumber, true)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          setMessages(prevMessages => [
            ...prevMessages,
            { text: translatedTextentercorrecttktnum, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: 'bot' }
          ]);
          setAwaitingDescription(true)
          setShowAdditionalButtons(false);
          setShowTextfield(false);
        }
        else if (count === 3) {
          count = 0
          const countexhausted = 'You’ve exceeded the limit for incorrect ticket numbers. Please check and try again later. Thank you!'
          const translatedcountexhausted = await translateEnglishToOther(countexhausted, language);
          addDialog(countexhausted, true)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages(prevMessages => [
            ...prevMessages,
            { text: translatedcountexhausted, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: 'bot' }
          ]);
          console.log("Limit is over user entered non numeric");
          setShowMainMenuExitButtons(true)

        }
      }

      // Check if inputticket is a ticket number
      if (/^\d+$/.test(ticketnum)) {
        // If inputticket is a ticket number, fetch ticket details
        const response = await fetch(`${baseUrl}/api/ticketnum`, {
          method: "POST",
          headers: {
            "authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ticketnumber: ticketnum }),
        });

        if (!response.ok) {
          //throw new Error("Network response was not ok");
          datacount += 1
          console.log("Network response was not ok")
          console.log("Data count so far when response is not ok", datacount)
        }

        const data = await response.json();
        console.log("Ticket API response in line 220", data);
        console.log("Ticket API response in line 220", data.ticketnum)

        if (datacount < 3 && data.ticketnum == null) {
          const entercorrecttktnumber = "Please enter a correct ticket number";
          const translatedTextentercorrecttktnum =
            await translateEnglishToOther(entercorrecttktnumber, language);
          addDialog(entercorrecttktnumber, true)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedTextentercorrecttktnum, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
          ]);

          console.log("Data count so far", datacount)
          setAwaitingDescription(true);
          setShowTextfield(false);
        } else if (datacount === 3) {
          datacount = 0
          const countexhausted =
            "You’ve exceeded the limit for incorrect ticket numbers. Please check and try again later. Thank you!";
          const translatedcountexhausted = await translateEnglishToOther(
            countexhausted,
            language
          );
          addDialog(countexhausted, true)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedcountexhausted, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
          ]);
          console.log("Limit is over", datacount);
          setAwaitingDescription(false);
          setShowTextfield(false);
          setShowAdditionalButtons(false);
          setShowMainMenuExitButtons(true);
        } else {
          // Assuming the API returns a ticket details object
          const ticketDetails = [
            "Ticket Number",
            "Ticket Summary",
            "Ticket State",
          ];
          const translatedTicketDetails = await translateMultipleEnglishToOther(
            ticketDetails,
            language
          );
          console.log("Inside get ticket details functions");
          const tktresponse = `${translatedTicketDetails[ticketDetails[0]]} ${data.ticketnum} ${translatedTicketDetails[ticketDetails[1]]} ${data.summary} ${translatedTicketDetails[ticketDetails[2]]} ${data.status}`
          const engtktresponse = `Here are your ticket details: Ticket Number: ${data.ticketnum}, Ticket Summary: ${data.summary}, Ticket State: ${data.status}`;
          addDialog(engtktresponse, true)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          const transcardtitle = await translateEnglishToOther("Ticket Details", language)
          const cardtktdetails = {
            title: `${transcardtitle}`,
            numbertkt: `${translatedTicketDetails[ticketDetails[0]]}: ${data.ticketnum}`,
            tktsummary: `${translatedTicketDetails[ticketDetails[1]]}: ${data.summary}`,
            statetkt: `${translatedTicketDetails[ticketDetails[2]]}: ${data.status}`
          }
          setOtherButtonsVisible(false); // Hide other buttons
          setmenuafterticketdetails(cardtktdetails)
          setAwaitingDescription(false);
          setShowTextfield(false);
          setShowAdditionalButtons(false);
          setShowFeedbackInGetTicketDetails(true);
          // showFeedbackOptions(true);
          // setFeedbackOptionsVisible(true);

        }
      }
    } catch (error) {
      console.log("Error in getticketdetails function in chatbot.js file ", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Sorry, there was an error processing your request.  Please close your browser window and start a new chat session",
          createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
          from: "bot",
        },
      ]);
    }
  };

  // useEffect function will be triggered by default when the bot gets loaded this will provide the Inital Greeting Message
  useEffect(() => {
    async function first(){
    const initialMsg = `Hello ${user.name}, Please choose your preferred language of communication.`
    const initializeSession = async () => {
      newSessionId = await generateSessionId();
      setSessionId(newSessionId);
      console.log('Generated Session IDD:', newSessionId);
      console.log("Set session id", sessionId)
    };
    await initializeSession();

    //await initializeSession();
    try {
      await addDialog(initialMsg, true);
      // await getConversationHistory();
      console.log("Conversation History in code", conversationHistory)
      setMessages(
        [
          {
            text: initialMsg,
            createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
            from: 'bot',
          },
        ]);

      // await updateRedis(conversationHistory, newSessionId, token)
      dbemail = user.username
      console.log("User email to update in Document DB", dbemail)
      //var timestart = new Date()
      starttime = new Date()
      dbstarttime = starttime.toISOString().slice(0, 16)
      dbjustdate = starttime.toISOString().slice(0, 10)
      dbtimestamp = starttime.toISOString();
      dbdocdbid = null
      console.log("Time now:", dbstarttime)
      console.log("Only date", dbjustdate)
      console.log("Full time stamp", dbtimestamp)

      await updatedb();
    }
    catch (error) {
      console.error('Error in useEffect function in chatbot.js file -- This is the function that sets ups the Initial message in the Bot', error);
    }
  }
  first()
  }, []);

  // handlebacktotickets function will help in get the Last 5 tickets of the user. This function will be triggered when user already got a particular ticket details and from there the user selected Last 5 Tickets
  const handlebacktotickets = useCallback(async () => {
    setmenuafterticketdetails(false)
    setbuttonmenuafterticketdetails(false)
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log("User selected last 5 tickets after getting a ticket number details")
      const back2back = "Last 5 Tickets";
      const transback2back =
        await translateEnglishToOther(
          back2back,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transback2back, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
      ]);
      console.log('Session ID is valid');
      message = `Please wait for a moment`;
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);

      console.log("token value::", token);
      // Fetch the latest tickets data
      const response = await fetch(`${baseUrl}/api/mytickets?mail=${encodeURIComponent(user.username)}`, {
        headers: {
          "authorization": `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Inside handleback to tickets", data);
      if (Array.isArray(data) && data.length > 0) {
        const moreTicketMessage = "Please find your recent tickets below. If you would like to see more tickets please"
        const transmoreTicketMessage = await translateEnglishToOther(
          moreTicketMessage,
          language
        );
        const hereclick = "click here"
        const transhereclick = await translateEnglishToOther(
          hereclick,
          language
        )
        const haloUrl = `${config.Halo_Ticket_URL}/portal/tickets?btn=60&viewid=0`;
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: (
              <span>
                {transmoreTicketMessage}{" "}
                <a href={haloUrl} target="_blank" rel="noopener noreferrer"> {transhereclick} </a>
              </span>
            ),
            createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
            from: "bot",
          },
        ]);
        addDialog(moreTicketMessage, true)
        // await getConversationHistory()
        // await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setTickets(data);
        setsearchwithtktnum(false);
        setMyTicketsExtraButtonsVisible(false);
        setShowMoreTicketDetailsButton(false);
        setOtherButtonsVisible(false);
        setbacktotickets(false);
        setexitchat(false);
        setchatliveagent(false);
        setShowAdditionalButtons(false);
        setShowMainMenuExitButtons(false);
      } else {
        setTickets([]);
        const notickets = "Couldn't find a ticket for your ID, Please click End Chat to exit the chat or return to the Main Menu.";
        const noticketstranslated = await translateEnglishToOther(
          notickets,
          language
        );
        addDialog(notickets, true)
        // await getConversationHistory();
        // await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: noticketstranslated, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
        ]);
        setShowMainMenuExitButtons(true);
      }
    } catch (error) {
      console.error("Error in handlebacktotickets function in chatbot.js file", error);
      setTickets([]);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "There was an error fetching tickets.", createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
    }
  }, [language]);

  // handleTicketSelection function captures the user selected ticket in the Last 5 tickets list and calls fetchTicketDetails function to get the selected ticket details
  const handleTicketSelection = async (ticket) => {
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log("User pressed a ticket after the user get the Last 5 tickets")
      console.log('Session ID is valid');
      setSelectedTicket(ticket);
      setTickets([])
      await fetchTicketDetails(ticket);
    }
    catch (error) {
      console.log("Error in handleTicketSelection function in chatbot.js file", error)
    }
  };

  // fetchTicketDetails function will be triggered once user selects a ticket in the Last 5 tickets list and fetches the selected ticket details
  const fetchTicketDetails = async (ticket) => {
    try {
      //////////When user selects a tickenumber provided in the last 5 ticket details this function will trigger
      csat = "last5tickets"
      setShowFeedbackInGetTicketDetails(true)
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: ticket, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
      ]);

      const response = await fetch(`${baseUrl}/api/ticketnum?aa=bb`, {
        method: "POST",
        headers: {
          "authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ticketnumber: ticket }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Ticket API response::", data);
      const ticketDetails = [
        "Ticket Number",
        "Ticket Summary",
        "Ticket State",
      ];
      const translatedTicketDetails = await translateMultipleEnglishToOther(
        ticketDetails,
        language
      );
      console.log("Inside fetch ticket details functions");
      const tktdetails = `${translatedTicketDetails[ticketDetails[0]]} ${data.ticketnum
        } ${translatedTicketDetails[ticketDetails[1]]} ${data.summary} ${translatedTicketDetails[ticketDetails[2]]
        } ${data.status}`;
      const engtktresponse = `Here are your ticket details: Ticket Number: ${data.ticketnum}, Ticket Summary: ${data.summary}, Ticket State: ${data.status}`;
      addDialog(ticket, false, user.name)
      addDialog(engtktresponse, true)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      const transcardtitle = await translateEnglishToOther("Ticket Details", language)
      const cardtktdetails = {
        title: `${transcardtitle}`,
        numbertkt: `${translatedTicketDetails[ticketDetails[0]]}: ${data.ticketnum}`,
        tktsummary: `${translatedTicketDetails[ticketDetails[1]]}: ${data.summary}`,
        statetkt: `${translatedTicketDetails[ticketDetails[2]]}: ${data.status}`
      }

      setOtherButtonsVisible(false); // Hide other buttons
      setAwaitingDescription(true);
      setShowTextfield(false);
      setmenuafterticketdetails(cardtktdetails);
      setShowAdditionalButtons(false);
      ticketnum = data.ticketnum
      console.log("Ticket number", data.ticketnum)
      console.log("ticketcardddd", menuafterticketdetails);
      console.log("Ticket number in line 1298", ticketnum);
    } catch (error) {
      console.error("Error in fetchTicketDetails function in chatbot.js file", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Sorry, there was an error processing your request.  Please close your browser window and start a new chat session",
          createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
          from: "bot",
        },
      ]);
    }
  };


  // handlesearchwithtktnum function will be triggered when user already opened a ticket details and presses Search with ticket number 
  const handlesearchwithtktnum = async () => {
    setmenuafterticketdetails(false)
    setbuttonmenuafterticketdetails(false)
    try {
      agentgenesys = false
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      const srchtktnum = "Search with Ticket Number";
      const transsrchtktnum =
        await translateEnglishToOther(
          srchtktnum,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transsrchtktnum, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
      ]);
      console.log('Session ID is valid');
      const tktsearch = 'Selected "Search with ticket number"'
      console.log("Entered handlesearch with ticket number. User presses search with ticket number after getting a ticket details", textarea);
      const enterticketnumber = "Please enter your ticket number";
      const enterticketnumbertranslated = await translateEnglishToOther(
        enterticketnumber,
        language
      );
      addDialog(tktsearch, false, user.name)
      addDialog(enterticketnumber, true)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: enterticketnumbertranslated, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      console.log("count in handle search with tkt num", count)
      setShowMoreTicketDetailsButton(false);
      setShowMainMenuExitButtons(false); // Show the "Show more ticket details" button
      setOtherButtonsVisible(false); // Hide other buttons
      setexitchat(false);
      setchatliveagent(false);
      setbacktotickets(false);
      setsearchwithtktnum(false);
      setTickets(false);
      setAwaitingDescription(true);
      setShowTextfield(false);
    }
    catch (error){
      console.log("Error in handlesearchwithtktnum function in chatbot.js file", error)
    }
  };

  // handleShowMoreTicketDetails function open's the user selected ticket number in HALO.
  const handleShowMoreTicketDetails = async () => {
try{
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    setmenuafterticketdetails(null)
    console.log('Session ID is valid');
    const showmore = "Show More Ticket Details"
    const transshowmore =
      await translateEnglishToOther(
        showmore,
        language
      );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transshowmore, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
    ]);
    const moredetails = 'Selected "Show more ticket details"'
    addDialog(moredetails, false, user.name)
    // await getConversationHistory();
    // await updateRedis(conversationHistory, newSessionId, token)
    await updatedb();
    console.log("User selected ticket number in 349 line", ticketnum);
    
    window.open(
      `${config.Halo_Ticket_URL}/portal/ticket?btn=60&viewid=0&id=${ticketnum}`,
      "_blank"
    );
    setbuttonmenuafterticketdetails(true)
  }
  catch(error){
    console.log('Error in handleShowMoreTicketDetails function in chatbot.js file', error)
  }
  };
// kbLink function will be triggered when user presses on the Kb article button
  const kbLink = async (url, name) => {
    try{
      console.log(" KB LINKKKKKK FUNCTION")
    //setkbYesNoTranslations1(false);
    //setkblink(false);
    csatstate = "kbarticle"
    console.log("Updated csat in kblink function", csatstate)
    setkbYesNo(false);
    //setShowKBarticleButtons(false);
    console.log("language kb", languageKb);
    url = `${baseUrl}/api/getKbArticle?language=${languageKb}&fileName=${name}&token=${sessionStorage.getItem('swiss.api.access_token')}`
    window.open(`${url}`, "_blank"); // Open the KB article in a new tab
    openKBarticle.push(name); // Track opened articles
    const transqueryraise =
      await translateEnglishToOther(
        name,
        languageKb
      );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transqueryraise, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
    ]);
    addDialog(name, false, user.name); // Add user's dialog
    console.log("opened KB", openKBarticle);
    setKBhelpList((prevArticles) => [...prevArticles, transqueryraise]);
    // Prepare the follow-up message
    const message = "Please review the Knowledge based articles above and let me know if you found them helpful?";
    addDialog(message, true);
    // Update conversation history
    // await getConversationHistory();
    // await updateRedis(conversationHistory, newSessionId, token)
    await updatedb();
    //Translate and send the bot's message
    const translatedMessage = await translateEnglishToOther(message, language);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
    ]);
    //Prepare Yes/No options
    const noKbOptions = ["Yes", "No"];
    const translatedNoKbTexts = await translateMultipleEnglishToOther(noKbOptions, language);
    setShowKBhelpList(false)
    setkbYesNo(true);
    setkbYesNoTranslations(translatedNoKbTexts);
  }
  catch(error){
    console.log('Error in kbLink function in chatbot.js file', error)
  }
  };

// handleexit function Ends the chat and sends a chat ending message to user. Also when user opend any ticket details and pressed End chat from there it will ask for rating
  const handleexit = async () => {
    try {
      setmenuafterticketdetails(null)
      setbuttonmenuafterticketdetails(false)
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      setmenuafterticketdetails(false)
      console.log('Session ID is valid');
      const chatend = "End Chat";
      const transchatend =
        await translateEnglishToOther(
          chatend,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transchatend, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
      ]);
      // const chatexit = 'Selected "End chat"'
      // const exitchatmsg = "Thank you for chatting. Have nice day!";
      // console.log("language inside handle exit", language);
      // const translatedTextexitchatmsg = await translateEnglishToOther(
      //   exitchatmsg,
      //   language
      // );
      // addDialog(chatexit, false, user.name)
      // addDialog(exitchatmsg, true)
      // // await getConversationHistory();
      // // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   { text: translatedTextexitchatmsg, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      // ]);

      // if (showFeedbackInGetTicketDetails){
      //   showFeedbackOptions(true);
      //   setFeedbackOptionsVisible(true);
      //   setShowFeedbackInGetTicketDetails(false);
      // } else {
      //   setFeedbackOptionsVisible(false);
      // }
      switch (csat) {
        // case "agent":
        //   csatstate = "agent"
        //   csat = ''
        //   //setFeedbackOptionsVisible(true);
        //   break;
        case "last5tickets":
          csatstate = "last5tickets"
          console.log("CSAT of last 5 tickets")
          csat = ''
          await showFeedbackOptions()
          break;
        case "searchwithticket":
          csatstate = "searchwithticket"
          console.log("CSAT of search with ticket number")
          csat = ''
          await showFeedbackOptions()
          break;
        default:
          setShowMainMenuExitButtons(false);
          console.log("inside deafult in handle exit function")
          const chatexit = 'Selected "End chat"'
          const exitchatmsg = "Thank you for chatting. Have nice day!";
          console.log("language inside handle exit", language);
          const translatedTextexitchatmsg = await translateEnglishToOther(
            exitchatmsg,
            language
          );
          
          addDialog(chatexit, false, user.name)
          addDialog(exitchatmsg, true)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedTextexitchatmsg, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
          ]);
          setShowMoreTicketDetailsButton(false);
           // Show the "Show more ticket details" button
          setOtherButtonsVisible(false); // Hide other buttons
          setexitchat(false);
          setchatliveagent(false);
          setbacktotickets(false);
          setsearchwithtktnum(false);
          setTickets(false);
          setAwaitingDescription(false)
          setShowTextfield(false);
        // setFeedbackOptionsVisible(true)


      }
    }
    catch(error) {
      console.log("Error in handleexit function in chatbot.js file", error)
    }
  };

   // handleagentuser function is related to Genesys live agent. This will be triggered whenever user sends Message during the live agent conversation
   const handleagentuser = async (input) => {
    filestate = false
  console.log("file state in handleagentuser function", filestate)
   if (uploadedstate === true && attachmentsizestate === true){
     setFile(true)
     attachmentsizestate = false
     console.log("File size is exceeded")
   }
   else{
    setFile(false)
    setIsFile(null)
   }
    console.log("In else inside hanldeagentuser function", uploadedstate)
    var uploadfilebutton = "Upload File"
    transuploadfilebutton = await translateEnglishToOther(uploadfilebutton, language)
    setIsVisible(true)
    let modify1;
    console.log("Ws in handle agent start:::", ws)
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    console.log("Input Inside handleagentuser", input); 
    // inputtostring = String(input)
    // console.log("AFTER CONVERTED TO STRING", inputtostring)
    //console.log("type of convereted number" ,typeof(inputtostring))
    setInput('')
    if (uploadedstate === false) {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: input, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
    ]);
    }
    //setInput('');
    try {

      const sendMessageToGenesys = async (message, tokenGenesys) => {
        console.log("Ws in handle agent user :::", ws)
        return new Promise((resolve, reject) => {
          if (!ws) {
            reject(new Error('WebSocket connection not established'));
            return;
          }
          if (uploadedstate === false) {
          if (message && message.text) {
              const onMessagePayload = JSON.stringify({
              action: 'onMessage',
              token: tokenGenesys,
              message: message
              });
            ws.send(onMessagePayload);
            console.log('Message sent to Genesys in handle agent user :', onMessagePayload);
            }
          }
          else if (uploadedstate === true) {
            uploadedstate = false
          }
          else {
            reject(new Error('Message payload is missing'));
          }
          ws.onmessage = async (event) => {
             let parsedData;
            try {
              parsedData = JSON.parse(event.data);
            } catch (err) {
              console.error('Error parsing JSON:', err);
              return;
            }
            console.log('Received message from Genesys in handleagentuser', parsedData);
            console.log('Received message from Genesys in handleagentuser with out parsingggggggggg', event.data);
            console.log("Agent name is about to get updated in handle agent user function")
            var agentName = parsedData.body.channel.from.nickname
            //dbagentname = agentName
            console.log("Agent name in ws.onmessage in handleagentuser",agentName)
            //await updatedb();
            var typeconv = parsedData.body.type
            var fromGenesys = parsedData.body.text;
            try{
              var event1 =  parsedData.body.events[0].eventType;
              console.log("----1 Event",event1)
            }
              catch(error){
                console.log("Error in Event1", error)
              }
            
            // Finding the "Disconnect" event
           
            async function handleMessage(parsedData, language, userName) {
              console.log("TYPE CONV", typeconv)
              console.log("Session config", sessionconfig)
              console.log("EVENT1",event1)
  
              if (typeconv === 'Event' && sessionconfig == true && event1 == "Typing") {
                // Set the flag to true to prevent re-execution
                console.log("---01 Agent connection")
                sessionconfig = false
                hasExecuted = true; 
                console.log("SESSION CONFIG INSIDE IF OF HANDLE AGENT USER",sessionconfig)
                console.log("inside if of handleMessage in handle agent user function")
                console.log("Agent name in Genesys in handle agent user function", agentName);
                var greeting = "You are now connected with";
                const translatedGreeting = await translateEnglishToOther(greeting, language);
                var greetingAndAgentName = `You are now connected with ${agentName}`;
                var translatedGreetingAndAgentName = `${translatedGreeting} ${agentName}`;
                addDialog(greetingAndAgentName, true, userName, agentName);
                // await getConversationHistory();
                // await updateRedis(conversationHistory, newSessionId, token)
                dbagentname = agentName
                await updatedb()
                console.log("AGENT NAME IN HANDLE AGENT USER", dbagentname)
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { text: translatedGreetingAndAgentName, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                ]);
                // console.log("Message from Genesys after call reached to agent inside handle message function", fromGenesys);
                // const translatedFromGenesys = await translateEnglishToOther(fromGenesys, language);
                // addDialog(fromGenesys, true, userName, agentName);
                // await getConversationHistory();
                // await updateRedis(conversationHistory, newSessionId, token)
                //await updatedb();
                // setMessages((prevMessages) => [
                //   ...prevMessages,
                //   { text: translatedFromGenesys, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                // ]);
              }
              else {
                console.log("UNABLE TO CAPTURE AGENT NAME IN HANDLE AGENT USER FUNCTION")
              }
            }
            console.log("Langauge before calling handle message function in handke agent user", language)
            await handleMessage(parsedData, language, userName)
            if (parsedData.body.type == 'Text') {
              console.log("Type::::", parsedData.body.type)
          
              // Check the structure of the message to ensure it contains the desired data
              if (parsedData.type === 'message' && parsedData.class === 'StructuredMessage') {
                const body = parsedData.body;
                if (body.direction === 'Outbound') {
                  // Extract the text
                  const text = body.text;
                  console.log('Modified message from Genesys in on message of handleagentuser function:', text);

                  sessionconfig = false
                  console.log('SESSION STATE AFTER BOT RECEIVES A MESSAGE', sessionconfig);


                  
                  // Store the text in a variable
                  modify1 = text;
                  const translatedgenesysinput = await translateEnglishToOther(
                    modify1,
                    language
                  );
                  console.log("Agent name in Genesys in handleagentUserrr", agentName)
                  console.log("Username in handleagentUserrr", userName)
                  addDialog(modify1, true, userName, agentName)
                  // await getConversationHistory();
                  // await updateRedis(conversationHistory, newSessionId, token)
                  await updatedb();
                  console.log("text::", modify1)
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: translatedgenesysinput, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                  ]);
                }
              }
            }
            const events = parsedData.body.events;
            // Finding the "Disconnect" event
            const disconnectEvent = events.find(event => event.presence && event.presence.type === "Disconnect");
            if (disconnectEvent) {
              setFile(false)
              setIsVisible(false)
              disconnection = true
              sessionconfig = false
              console.log("Session config state in handle agent user disconnect", sessionconfig)
              console.log("Disconnect event found in handleagent user", disconnectEvent);
              //else if(parsedData.body.events.presence.type == "Disconnect"){
              var agentName = parsedData.body.channel.from.nickname
              //dbagentname = agentName

              console.log("Agent name in Genesys in handleagentUser inside if of disconnect inside handleagentuser", agentName)
              var disconnectmsg = "Your conversation is closed by live agent. Have nice day!!"
              addDialog(disconnectmsg, true, userName, agentName)
              await updatedb()
              agentgenesys = false
              // await getConversationHistory();
              // await updateRedis(conversationHistory, newSessionId, token)
              console.log("user name", userName)
              console.log("Agent name", agentName)
              //await updatedb();
              const translateddisconnectmsg = await translateEnglishToOther(
                disconnectmsg,
                language
              );
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: translateddisconnectmsg, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
              ]);
              setAwaitingDescription(false)
              setShowTextfield(false);
              await showFeedbackOptions()
              //setShowMainMenuExitButtons(true)
            } else {
              console.log("No disconnect event found.");
            }
            try {
              const parsedData = JSON.parse(event.data);
              if (parsedData.class === 'StructuredMessage') {
                if (parsedData.body.direction === 'Inbound') {
                  console.log('Received inbound message from Genesys in  handle agent user:', parsedData.body.text);
                  resolve(parsedData.body.text);
                } else {
                  console.log('Received outbound message or other types of message in handle agent user inside structured message', parsedData);
                }
              } else {
                console.log('Received other message:', parsedData);
              }
            } catch (err) {
              console.error('Error parsing message:', err);
              reject(err);
            }
          };
          ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject(error);
          };
          // setTimeout(() => {
          //   reject(new Error('Timeout: No response from Genesys'));
          // }, 10000); // 10 seconds timeout
        });
      };

      const runWebSocket = async () => {
        const url = `wss://webmessaging.aps1.pure.cloud/v1?deploymentId=${config.Genesys_Deployment_ID}`;
        const tokenGenesys = newSessionId; // Replace with your token
        try {
          const translatedusermessage = await translateOtherToEnglish(
            input,
            language
          );
          const translatedusermessagetostring = String(translatedusermessage)
          console.log("TEXT IN RUN WEB SOCKET OF HANDLE AGENT USER FUNCTION", translatedusermessagetostring)
          console.log("Type after translation", typeof(translatedusermessage))
          addDialog(translatedusermessage, false, user.name, agentName)
          await updatedb()
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token);
          console.log("Language before passing the call to Genesys in handle agent user", Lan)
          console.log("User's name before passing the call to Genesys in handle agent user ", user.name)
          console.log("User's mail before passing the call to Genesys in handle agent user", user.username)
          const message = {
            type: 'Text',
            text: translatedusermessagetostring,
            "channel": {
              "metadata": {
                "customAttributes": {
                  "language": Lan,
                  "username": user.name,
                  "useremail": user.username,
                  "intent": ""
                }
              }
            }
          }
          disconnection = ''
          const response = await sendMessageToGenesys(message, tokenGenesys);
          dbliveagent = 'Yes'
          console.log("Live agent before updating in document db inside handleagent user", dbliveagent)
          await updatedb();
          console.log('Message from Genesys:', response);
        } catch (error) {
          console.error('Error during WebSocket interaction:', error);
        }
      };
      await runWebSocket()
    }
    catch (error) {
      console.log("Error in handleagentuser function in chatbot.js file", error)
    }
    
  }

// handleAgent function is related to Genesys live agent, this function is responsible for creating a session in Genesys and establishes the connection
  const handleAgent = async () => {
    try {
      csatstate = "agent"
      csat = ''
      setShowMoreTicketDetailsButton(false);
      setShowMainMenuExitButtons(false); // Show the "Show more ticket details" button
      setOtherButtonsVisible(false); // Hide other buttons
      setexitchat(false);
      setchatliveagent(false);
      setbacktotickets(false);
      setsearchwithtktnum(false);
      setTickets(false);
      setAwaitingDescription(true)
      setShowTextfield(false);
      //setShowMainMenuExitButtons(false)
      setExtraButtonsVisible(false)
      setNoKbButtonsVisible(false)
      setcreateIncidentsVisible(false)
      setSelfDropdownVisible(false)
      setShowSevenOptions(false)
      setSelectedOption(false)
      setSelectedCard(false)
      setShowAdditionalButtons(false);
      //setSubcategories(false)
      setShowSubcategories(false);
      setmenuafterticketdetails(null)
      setbuttonmenuafterticketdetails(false)
      var fromGenesys;
      //setAwaitingDescription(false)
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      const agenthandle = "Chat with live agent ";
      const transagenthandle =
        await translateEnglishToOther(
          agenthandle,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transagenthandle, from: "user" },
      ]);
      console.log('Session ID is valid');
      const checkagent = 'Selected "Live Agent"'
      setSelectedCard(null);
      const liveagenttext = "Please wait while I connect you with a live agent. Your chat will be answered in 30 sec";
      console.log("Language in line 1519", language)
      const translatedliveagent = await translateEnglishToOther(
        liveagenttext,
        language
      );
      var translan = language

      addDialog(checkagent, false, user.name)
      addDialog(liveagenttext, true)
      agentgenesys = true
      //// await getConversationHistory();
      //// await updateRedis(conversationHistory, newSessionId, token);
      
      //convo = await getRedisConvo(newSessionId, token);
      //console.log("After triggering getredis function", convo)
      await updatedb()
      console.log("type of data", typeof (convdatasofar))
      convsdata = await formatConversation(JSON.stringify(convdatasofar));
      console.log("Formated conversation in handleagent", convsdata);
      console.log("type of convs data in live agent", typeof convsdata)

      const configureSession = async (url, tokenGenesys, deploymentId, startNew) => {
        sessionconfig = true
        uploadedstate = false
        console.log("Inside the configure session session config state", sessionconfig)

        return new Promise((resolve, reject) => {
          console.log("Inside the configure session return promise")
          if (ws) {
            console.log('WebSocket already established');
            resolve();
            return;
          }

          ws = new WebSocket(url);

          console.log("Ws in handle agent:::", ws)
          ws.onopen = () => {
            console.log('WebSocket connection established');

            const configureSessionPayload = JSON.stringify({
              action: 'configureSession',
              token: tokenGenesys,
              deploymentId: deploymentId,
              startNew: startNew
            });

            ws.send(configureSessionPayload);
            //handleWebSocket(ws)
            console.log('Session configuration message sent:', configureSessionPayload);
          };

          ws.onmessage = (event) => {
            console.log('Received message during session configuration:', event.data);

            try {
              const parsedData = JSON.parse(event.data);
              if (parsedData.type === 'response' && parsedData.class === 'SessionResponse' && parsedData.code === 200) {
                console.log('Session successfully configured:', parsedData);
                resolve();
              } else {
                console.log('Unexpected response during session configuration:', parsedData);
              }
            } catch (err) {
              console.error('Error parsing session configuration response:', err);
              reject(err);
            }
          };

          ws.onerror = (error) => {
            console.error('WebSocket error during session configuration:', error);
            reject(error);
          };
        
         
          ws.onclose = async () => {
            if (disconnection != true) {
              filestate = true // this is needed to add "As we did not hear back from you" Dialog as Bot: "As we did not hear back from you" in the addDialog function
              setFile(false)
              setIsVisible(false)
            ws = null;
            timeout = "Yes"
            console.log('WebSocket connection closed');
            const closestatment = "As we did not hear back from you, we are closing this conversation. Please start a new conversation If you have any queries. Have a nice day!"
            const translatedclosestatment = await translateEnglishToOther(
              closestatment,
              language
            );
            addDialog(closestatment, true)
            console.log("Time out", timeout)
            await updatedb()
            // await getConversationHistory();
            // await updateRedis(conversationHistory, newSessionId, token);;
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedclosestatment, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
            ]);
            setAwaitingDescription(false)
          }
            // Reset WebSocket instance
          };
        //   function handleWebSocket(ws) {
        //     setInterval (() =>{
        //       try{
        //     console.log("Triggereddddddddddddddddddddddddddddddddd")
        //     // Send a message to the server
        //     //if (ws) {
        //       const onMessagePayload = JSON.stringify({
        //         action: 'echo',
        //         message: {
        //           "type": "Text",
        //           "text": "ping"
        //         }
        //         });
        //       ws.send(onMessagePayload);
        //       console.log('Message sent in helath check:', message);
        //     // } else {
        //     //   console.log('WebSocket is not open. Unable to send message.');
        //     // }
           
        //     // Handle the response from the server
        //     // ws.onmessage = (event) => {
        //     //   console.log('Message received from server after helath check:', event.data);
        //     //   try {
        //     //     const response = JSON.parse(event.data); // Parse if response is JSON
        //     //     console.log('Parsed response:', response);
        //     //   } catch (error) {
        //     //     console.log('Non-JSON response:', event.data);
        //     //   }
        //     // };
        //   }
        //   catch(error){
        //     console.log("Error while sending Echo", error)
        //   }
        // }
        //   ,60000);
        // }
          

          // setTimeout(() => {
          //   reject(new Error('Timeout: No response from Genesys'));
          // }, 10000); // 10 seconds timeout
        });
      };
      // Sending the conversation BOT and USER had so far to
      const sendMessageToGenesys = (message, tokenGenesys) => {
        return new Promise((resolve, reject) => {
          if (!ws) {
            reject(new Error('WebSocket connection not established'));
            return;
          }

          if (message && message.text) {
            const onMessagePayload = JSON.stringify({
              action: 'onMessage',
              token: tokenGenesys,
              message: message
            });

            ws.send(onMessagePayload);
            console.log('Message sent to Genesys:', onMessagePayload);
          } else {
            reject(new Error('Message payload is missing'));
          }

          ws.onmessage = async (event) => {
            console.log('Received message from Genesys in handle agent:', event.data);

            try {
              const parsedData = JSON.parse(event.data);
              if (parsedData.class === 'StructuredMessage') {
                if (parsedData.body.direction === 'Inbound') {
                  console.log('Received inbound message from Genesys:', parsedData.body.text);
                  resolve(parsedData.body.text);
                } else {
                  console.log('Received outbound message from genesys with outbound direction in handle agent', parsedData);
                  console.log("Received outbound message from genesys with outbound direction with out parse in handle agent", event.data)
                  console.log("Agent name is about to get updated in handle agent function")

                  var typeconv = parsedData.body.type
                  var agentName = parsedData.body.channel.from.nickname;
                  dbagentname = agentName
                  await updatedb()
                  fromGenesys = parsedData.body.text;
                  console.log("Conversation type", typeconv)
                  console.log(" meesage typed by agent", fromGenesys)
                  const events = parsedData.body.events;
                  if (parsedData && parsedData.body && Array.isArray(parsedData.body.events)) {

                    const disconnectEvent = events.find(event => event.presence && event.presence.type === "Disconnect");
                    console.log("Disconect::", disconnectEvent)
                    if (disconnectEvent) {
                      setFile(false)
                      setIsVisible(false)
                      sessionconfig = false
                      disconnection = true
                      console.log("Disconnect event found in handleagent function", disconnectEvent);

                      //else if(parsedData.body.events.presence.type == "Disconnect"){
                      var agentName = parsedData.body.channel.from.nickname
                      //dbagentname = agentName
                      console.log("Agent name in Genesys in handleagentUser inside if of disconnect inside handleagent", agentName)
                      var disconnectmsg = "Your conversation is closed by live agent. Have nice day!!"
                      agentgenesys = false
                      await updatedb()
                      addDialog(disconnectmsg , true , userName, agentName)
                      // await getConversationHistory();
                      // await updateRedis(conversationHistory, newSessionId, token);
                      //await updatedb()
                      const translateddisconnectmsg = await translateEnglishToOther(
                        disconnectmsg,
                        language
                      );
                      setMessages((prevMessages) => [
                        ...prevMessages,
                        { text: translateddisconnectmsg, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                      ]);
                      setAwaitingDescription(false)
                      setShowTextfield(false);
                      await showFeedbackOptions()
                      //setFeedbackOptionsVisible(true)
                      //setShowMainMenuExitButtons(true)

                    }
                    else {
                      async function handleMessage(parsedData, language, userName) {

                        if (typeconv === 'Event' && !hasExecuted || disconnection == true) {
                          // Set the flag to true to prevent re-execution
                          hasExecuted = true;
                          sessionconfig = false
                          console.log("inside if of handleMessage")
                          disconnection = ''
                          console.log("Agent name in Genesys", agentName);
                          console.log("---02 Agent connection")
                          var greeting = "You are now connected with";
                          console.log("Langauge in handle agent function inside handlemessage function", translan)
                          const translatedGreeting = await translateEnglishToOther(greeting, translan);
                          var greetingAndAgentName = `You are now connected with ${agentName}`;
                          var translatedGreetingAndAgentName = `${translatedGreeting} ${agentName}`;

                          addDialog(greetingAndAgentName, true, userName, agentName);
                          // await getConversationHistory();
                          // await updateRedis(conversationHistory, newSessionId, token);;
                          setMessages((prevMessages) => [
                            ...prevMessages,
                            { text: translatedGreetingAndAgentName, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                          ]);


                          console.log("Message from Genesys after call reached to agent inside handle message function", fromGenesys);
                          console.log("Langauge in handle agent  above from genesys line inside handle message function", language)
                          if (fromGenesys != undefined) {
                            console.log("From Genesys is undefind", fromGenesys)
                            const translatedFromGenesys = await translateEnglishToOther(fromGenesys, language);
                            addDialog(fromGenesys, true, userName, agentName);
                            // await getConversationHistory();
                            // await updateRedis(conversationHistory, newSessionId, token);;
                            setMessages((prevMessages) => [
                              ...prevMessages,
                              { text: translatedFromGenesys, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                            ]);
                          }
                        }
                        else if (fromGenesys != undefined) {
                          console.log("UNABLE TO CAPTURE AGENT NAME HANDLE AGENT FUNCTION")
                          fromGenesys = parsedData.body.text
                          console.log("Message from Gensys after call reached to agent inside handle message function insdie else if", fromGenesys)
                          console.log("Language in handle agent  above from genesys line", language)
                          if (!fromGenesys) {
                            const translatedfromgenesys = await translateEnglishToOther(
                              fromGenesys,
                              language
                            );
                            addDialog(fromGenesys, true, userName, agentName)
                            await updatedb()
                            // await getConversationHistory();
                            // await updateRedis(conversationHistory, newSessionId, token);;
                            setMessages((prevMessages) => [
                              ...prevMessages,
                              { text: translatedfromgenesys, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                            ]);
                          }

                        }
                      }
                      await handleMessage(language);
                      if (fromGenesys != undefined) {
                        console.log("Langauge before calling handle message function in handke agent", language)
                        await handleMessage(parsedData, language, userName)

                        fromGenesys = parsedData.body.text
                        console.log("Message from Gensys after call reached to agent:::::", fromGenesys)
                        console.log("Langauge in handle agent  above from genesys line", language)
                        const translatedfromGenesys = await translateEnglishToOther(
                          fromGenesys,
                          language
                        );
                        addDialog(fromGenesys, true, userName, agentName)
                        // await getConversationHistory();
                        // await updateRedis(conversationHistory, newSessionId, token);;
                        setMessages((prevMessages) => [
                          ...prevMessages,
                          { text: translatedfromGenesys, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                        ]);
                      }


                    }
                  }
                  else {
                    console.log("else 1812", fromGenesys)
                    fromGenesys = parsedData.body.text
                    console.log("Message from Gensys after call reached to agent:::::", fromGenesys)
                    console.log("Langauge in handle agent  above from genesys line", language)
                    const translatedfromGenesys = await translateEnglishToOther(
                      fromGenesys,
                      language
                    );
                    addDialog(fromGenesys, true, userName, agentName)
                    // await getConversationHistory();
                    // await updateRedis(conversationHistory, newSessionId, token);;
                    setMessages((prevMessages) => [
                      ...prevMessages,
                      { text: translatedfromGenesys, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
                    ]);

                  }

                }
              } else {
                console.log('Received other message:', parsedData);
              }
            } catch (err) {
              console.error('Error parsing message:', err);
              reject(err);
            }
          };

          ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject(error);
          };

          // setTimeout(() => {
          //   reject(new Error('Timeout: No response from Genesys'));
          // }, 10000); // 10 seconds timeout
        });
      };


      const runWebSocket = async () => {
        const url = `wss://webmessaging.aps1.pure.cloud/v1?deploymentId=${config.Genesys_Deployment_ID}`;
        const tokenGenesys = newSessionId; // Replace with your token
        const deploymentId = config.Genesys_Deployment_ID;
        const startNew = "true" // Replace with your deployment ID

        try {
          // Configure the session once
          fileready = true
          console.log("Configure session file ready state", fileready)
          if (disconnection == true) {
          ws = null;
          await configureSession(url, tokenGenesys, deploymentId, startNew);
          console.log("User Genesys session is getting established more than one time")
          console.log("Language before passing the call to Genesys in handle agent", Lan)
          console.log("User's name before passing the call to Genesys in handle agent ", user.name)
          console.log("User's mail before passing the call to Genesys in handle agent", user.username)
          // Send a message after the session is configured
          const message = {
            type: 'Text', text: convsdata,

            "channel": {
              "metadata": {
                "customAttributes": {
                  "language": Lan,
                  "username": user.name,
                  "useremail": user.username,
                  "intent": ""
                }
              }
            }
          };
          dbliveagent = 'Yes'
          console.log("Live agent before updating in document db inside handleagent function", dbliveagent)
          await updatedb(); // Replace with your message
          const response = await sendMessageToGenesys(message, tokenGenesys);
          console.log('Message from Genesys:', response);
            
          }
          else{
          console.log("User Genesys session is getting established for the first time")
          await configureSession(url, tokenGenesys, deploymentId);
          console.log("Language before passing the call to Genesys in handle agent", Lan)
          console.log("User's name before passing the call to Genesys in handle agent ", user.name)
          console.log("User's mail before passing the call to Genesys in handle agent", user.username)
          // Send a message after the session is configured
          const message = {
            type: 'Text', text: convsdata,

            "channel": {
              "metadata": {
                "customAttributes": {
                  "language": Lan,
                  "username": user.name,
                  "useremail": user.username,
                  "intent": ""
                }
              }
            }
          };
          dbliveagent = 'Yes'
          console.log("Live agent before updating in document db inside handleagent function", dbliveagent)
          await updatedb(); // Replace with your message
          const response = await sendMessageToGenesys(message, tokenGenesys);
          console.log('Message from Genesys:', response);
        }
          // setAwaitingDescription(true)
        } catch (error) {
          console.error('Error during WebSocket interaction:', error);
        }
      };

      await runWebSocket();

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedliveagent, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      //await getConversationHistoryFromRedis();
      //await handleagentuser();
      // setShowMoreTicketDetailsButton(false);
      // setShowMainMenuExitButtons(false); // Show the "Show more ticket details" button
      // setOtherButtonsVisible(false); // Hide other buttons
      // setexitchat(false);
      // setchatliveagent(false);
      // setbacktotickets(false);
      // setsearchwithtktnum(false);
      // setTickets(false);
      // setAwaitingDescription(true)
      // //setShowMainMenuExitButtons(false)
      // setExtraButtonsVisible(false)
      // setNoKbButtonsVisible(false)
      // setcreateIncidentsVisible(false)
      // setSelfDropdownVisible(false)
      // setShowSevenOptions(false)
      // setSelectedOption(false)
      // setSelectedCard(false)
      // setShowAdditionalButtons(false);
      // //setSubcategories(false)
      // setShowSubcategories(false);
    }
    catch (error) {
      console.error("Error in handleAgent function in chatbot.js file", error)
    }

    // Add your logic to handle live agent chat initiation
  };
// handleFileChange function helps to select the file, this function will check the extension of the file selected by user
  const handleFileChange = async (event) => {
   
    filestate = true
    setFile(true)
    setFile(event.target.files[0]);

    console.log("Event", event)
    const file1 = event.target.files[0]
    console.log("File 1111", file1.name)
    nameoffile = file1.name
    console.log("Fileeeeee", file)
    if (file) {
      var attachmentdialog = "Please select files with .png .jpeg .gif extension"
      console.log("Language in file", language)
      var transattachmentdialog = await translateEnglishToOther(attachmentdialog, language)
      var typeoffile = file1.type
      console.log("File type in if", typeoffile)
      console.log("Type of file selected", typeof (typeoffile))
      
      if (typeoffile === "image/gif") {
        setIsFile(true)
        
        console.log("Correct type of file", typeoffile)
      }
      else if (typeoffile === "image/jpeg") {
        setIsFile(true)

        console.log("Correct type of file", typeoffile)
      }
      else if (typeoffile === "image/png") {
        setIsFile(true)

        console.log("Correct type of file", typeoffile)
      }
      else {
        setIsFile(null)
        console.log("Incorrect file type", typeoffile)
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: transattachmentdialog, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" }
        ]);
      addDialog(attachmentdialog, true)
      await updatedb()
        event.target.files[0] = null
        console.log("state of file now", event.target.files[0])



      }
    }
    console.log("In handlefilechange function")
  };

  // handleUpload function helps to send the file to Genesys
  const handleUpload = async () => {
    filestate = true
    if (!file) return;
  
    try {
      var url1
      var fileheader
      var headers
      var attach = null

      const sendMessageToGenesys = (message, tokenGenesys) => {
        return new Promise(async (resolve, reject) => {
          if (!ws) {
            reject(new Error('WebSocket connection not established'));
            return;
          }

          else {
            setFile(false)
            var wait = "Please wait for a moment"
            var transwait = await translateEnglishToOther(wait, language)
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transwait, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" }
            ]);

            const requestPayload = {
              action: "onAttachment",
              fileName: file.name,
              fileType: file.type,
              fileSize: file.size,
              token: newSessionId
            };


            ws.send(JSON.stringify(requestPayload));
            console.log('Message sent to Genesys first step:', JSON.stringify(requestPayload));

            //reject(new Error('Message payload is missing'));
          }

          ws.onmessage = async (event) => {

            console.log('----Received message from Genesy First step:', event.data);
            let parsedData;
            try {
              parsedData = JSON.parse(event.data);
            } catch (err) {
              console.error('Error parsing JSON:', err);
              return;
            }
            console.log('---- Parsed Received message from Genesys first step:', parsedData);
            console.log('----Received message type from Genesys in handle agent:', parsedData.type);
            fileheader = "organizationId=7631160e-888b-443c-929e-9ea1b0b647c9&originPlatform=PureCloud&role=darth&owner=Dev-CloudAppsDarth@genesys.com"
            url1 = parsedData.body.url; // Your presigned URL
            console.log('---- attachment id in sendmessage to genesys function', parsedData.body.attachmentId)
            attach = parsedData.body.attachmentId;
            console.log('---file upload presigned url', url1);
            console.log('---x-amz headers', fileheader);

            headers = {
              "x-amz-tagging": fileheader
            };
            const uploadToPresignedUrl = async (url1, file, headers) => {
              try {
                // Perform a PUT request to upload the file to the presigned URL
                const response = await axios.put(url1, file, {
                  headers: {
                    ...headers,
                    'Content-Type': file.type,
                  },
                });

                if (response.status === 200) {
                  console.log("File uploaded successfully:", response);

                  try {
                    console.log("Attachment id after file uploaded sucessfully", attach)
                    //const requestPayload1 = {
                    //   "action": "onMessage",
                    //   "message": {
                    //     "type": "Text",
                    //     "text": "abc",
                    //     "content": [
                    //       {
                    //         "contentType": "Attachment",
                    //         "attachment": {
                    //           "id": attach
                    //         }
                    //       }
                    //     ]
                    //   },

                    //   "token": newSessionId
                    // }

                    // ws.send(JSON.stringify(requestPayload1));
                    //console.log('Message sent to Genesys after uploading the attachment id:', JSON.stringify(requestPayload1));
                    ws.onmessage = async (event) => {
                      let parsedData;
                      try {
                        parsedData = JSON.parse(event.data);
                      } catch (err) {
                        console.error('Error parsing JSON:', err);
                        return;
                      }
                      if (parsedData.code == 200 && parsedData.class == "UploadSuccessEvent") {
                        console.log('---- Parsed Received message from Genesys second step:', parsedData);
                        console.log("Attachment id :::", attach)
                        try {
                          const requestPayload1 = {
                            "action": "onMessage",
                            "message": {
                              "type": "Text",
                              "text": nameoffile,
                              "content": [
                                {
                                  "contentType": "Attachment",
                                  "attachment": {
                                    "id": attach
                                  }
                                }
                              ]
                            },

                            "token": newSessionId
                          }
                          ws.send(JSON.stringify(requestPayload1));
                          setFile(false)
                          setIsFile(null)
                          var fileuploaddialog = "uploaded successfully"
                          var transfileuploaddialog = await translateEnglishToOther(fileuploaddialog, language)
                          setMessages((prevMessages) => [
                            ...prevMessages,
                            { text: `${nameoffile} ${transfileuploaddialog}`, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
                          ]);
                          var uploaddialog = `${nameoffile} ${fileuploaddialog}`
                          console.log("user name in handleUpload function", user.name)
                          addDialog(uploaddialog, false, user.name, agentName)
                          await updatedb()

                        }
                        catch (error) {
                          console.log("error after uploading", error)
                        }

                      }
                      else {
                        console.log('status code 200:', parsedData);
                        uploadedstate = true
                        await handleagentuser()
                      }

                    }


                  }
                  catch (error) {
                    console.log('Failedddd to send attachment id after uploading the file', error);
                  } //reject(new Error('Message payload is missing'));


                } else {
                  console.error("Failed to upload file:", response);
                }
              } catch (error) {
                var sizeexceed = "Please make sure your file size is less than 10 MB"
                var transsizeexceed = await translateEnglishToOther(sizeexceed, language)
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { text: transsizeexceed, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" }
                ]);
               
                addDialog(sizeexceed, true)
                setFile(true)
                setIsFile(null)
                uploadedstate = true
                attachmentsizestate = true
                await handleagentuser()
                //console.error("Error uploading file to presigned URL:", error);

              }
            }

            await uploadToPresignedUrl(url1, file, headers)

            // try {
            //   const parsedData = JSON.parse(event.data);
            //   if (parsedData.class === 'StructuredMessage') {
            //     if (parsedData.body.direction === 'Inbound') {
            //       console.log('Received inbound message from Genesys:', parsedData.body.text);
            //       resolve(parsedData.body.text);
            //     } else {
            //       console.log('Received outbound message from genesys with outbound direction', parsedData);
            //       console.log("Received outbound message from genesys with outbound direction with out parse", event.data)

            //       var typeconv = parsedData.body.type
            //       var agentName = parsedData.body.channel.from.nickname;
            //       var fromGenesys = parsedData.body.text;
            //       console.log("Conversation type", typeconv)
            //       console.log(" meesage typed by agent", fromGenesys)
            //       const events = parsedData.body.events;
            //       if (parsedData && parsedData.body && Array.isArray(parsedData.body.events)) {
            //         const disconnectEvent = events.find(event => event.presence && event.presence.type === "Disconnect");
            //         console.log("Disconect::", disconnectEvent)
            //         if (disconnectEvent) {

            //           console.log("Disconnect event found:", disconnectEvent);

            //           //else if(parsedData.body.events.presence.type == "Disconnect"){
            //           var agentName = parsedData.body.channel.from.nickname
            //           console.log("Agent name in Genesys in handleagentUser inside if of disconnect", agentName)
            //           var disconnectmsg = "Your conversation is closed by live agent. Have nice day!!"
            //           agentgenesys = false
            //           addDialog(disconnectmsg, true, userName, agentName)
            //           await getConversationHistory();
            //           await updateRedis(conversationHistory, newSessionId, token)
            //           await updatedb();
            //           const translateddisconnectmsg = await translateEnglishToOther(
            //             disconnectmsg,
            //             language
            //           );
            //           setMessages((prevMessages) => [
            //             ...prevMessages,
            //             { text: translateddisconnectmsg, from: "bot" },
            //           ]);
            //           setAwaitingDescription(false)
            //           setShowMainMenuExitButtons(true)

            //         }
            //         else {
            //           async function handleMessage(parsedData, language, userName) {

            //             if (typeconv === 'Event' && !hasExecuted) {
            //               // Set the flag to true to prevent re-execution
            //               hasExecuted = true;
            //               console.log("inside if of handleMessage")

            //               console.log("Agent name in Genesys", agentName);

            //               var greeting = "You are now connected with";
            //               console.log("Langauge in handle agent function", language)
            //               const translatedGreeting = await translateEnglishToOther(greeting, language);
            //               var greetingAndAgentName = `You are now connected with ${agentName}`;
            //               var translatedGreetingAndAgentName = `${translatedGreeting} ${agentName}`;

            //               addDialog(greetingAndAgentName, true, userName, agentName);
            //               await getConversationHistory();
            //               await updateRedis(conversationHistory, newSessionId, token)
            //               await updatedb();
            //               setMessages((prevMessages) => [
            //                 ...prevMessages,
            //                 { text: translatedGreetingAndAgentName, from: "Bot" },
            //               ]);


            //               console.log("Message from Genesys after call reached to agent inside handle message function", fromGenesys);
            //               console.log("Langauge in handle agent  above from genesys line inside handle message function", language)
            //               if (!fromGenesys) {
            //                 console.log("From Genesys is undefind", fromGenesys)
            //                 const translatedFromGenesys = await translateEnglishToOther(fromGenesys, language);
            //                 addDialog(fromGenesys, true, userName, agentName);
            //                 await getConversationHistory();
            //                 await updateRedis(conversationHistory, newSessionId, token)
            //                 await updatedb();
            //                 setMessages((prevMessages) => [
            //                   ...prevMessages,
            //                   { text: translatedFromGenesys, from: "Bot" },
            //                 ]);
            //               }
            //             }
            //             else {
            //               fromGenesys = parsedData.body.text
            //               console.log("Message from Gensys after call reached to agent inside handle message function insdie else if", fromGenesys)
            //               console.log("Langauge in handle agent  above from genesys line", language)
            //               if (!fromGenesys) {
            //                 const translatedfromgenesys = await translateEnglishToOther(
            //                   fromGenesys,
            //                   language
            //                 );
            //                 addDialog(fromGenesys, true, userName, agentName)
            //                 await getConversationHistory();
            //                 await updateRedis(conversationHistory, newSessionId, token)
            //                 await updatedb();
            //                 setMessages((prevMessages) => [
            //                   ...prevMessages,
            //                   { text: translatedfromgenesys, from: "Bot" },
            //                 ]);
            //               }

            //             }
            //           }
            //           //await handleMessage(language);
            //           console.log("Langauge before calling handle message function in handke agent", language)
            //           await handleMessage(parsedData, language, userName)



            //           fromGenesys = parsedData.body.text
            //           console.log("Message from Gensys after call reached to agent:::::", fromGenesys)
            //           console.log("Langauge in handle agent  above from genesys line", language)
            //           const translatedfromGenesys = await translateEnglishToOther(
            //             fromGenesys,
            //             language
            //           );
            //           addDialog(fromGenesys, true, userName, agentName)
            //           await getConversationHistory();
            //           await updateRedis(conversationHistory, newSessionId, token)
            //           await updatedb();
            //           setMessages((prevMessages) => [
            //             ...prevMessages,
            //             { text: translatedfromGenesys, from: "Bot" },
            //           ]);



            //         }
            //       }
            //       else {
            //         console.log("else 1812", fromGenesys)
            //         fromGenesys = parsedData.body.text
            //         console.log("Message from Gensys after call reached to agent:::::", fromGenesys)
            //         console.log("Langauge in handle agent  above from genesys line", language)
            //         const translatedfromGenesys = await translateEnglishToOther(
            //           fromGenesys,
            //           language
            //         );
            //         addDialog(fromGenesys, true, userName, agentName)
            //         await getConversationHistory();
            //         await updateRedis(conversationHistory, newSessionId, token)
            //         await updatedb();
            //         setMessages((prevMessages) => [
            //           ...prevMessages,
            //           { text: translatedfromGenesys, from: "Bot" },
            //         ]);

            //       }

            //     }
            //   } else {
            //     console.log('Received other message:', parsedData);
            //   }
            // } catch (err) {
            //   console.error('Error parsing message:', err);
            //   reject(err);
            // }
            //return parsedData
          };

          ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject(error);
          };

          // setTimeout(() => {
          //   reject(new Error('Timeout: No response from Genesys'));
          // }, 10000); // 10 seconds timeout
        });
      };
      // Request a presigned URL

      //  const response = await axios.post('/api/request-presigned-url', requestPayload);
      //   const { url, headers, attachmentId } = response.data.body;

      //   // Upload the file to the presigned URL
      //   await axios.put(url, file, {
      //     headers: {
      //       ...headers,
      //       'Content-Type': file.type,
      //     },
      //   });

      // Send attachment ID to Genesys
      console.log("triggered send message to genesys function")
      const d = await sendMessageToGenesys();
      console.log("after triggered send message to genesys function", d)

    } catch (error) {
      console.error("File upload failed:", error);
      // Handle UploadFailureEvent if needed
    }
  };






  // handleSelectLanguage function triggers when user selects a langauge after user selects the language this function provides the options like My Tickets, reposrt an Issue, change language, change request 
  const handleSelectLanguage = async (selectedLanguage) => {
    new Promise(async (resolve, reject) => {
      console.log("handleSelectLanguage    selectedLanguage:", selectedLanguage)
      try {
        dbdocdbid = "sampledocid"
        console.log("Language", selectedLanguage);
        console.log("Docment id after user selected language", dbdocdbid);
        langauge = selectedLanguage || 'en';
        setLanguageKb(langauge)
        if (menumain !== true) {
          changelanstate = false
          console.log("Language state in handleselectlanguage function inside menumain!=true", changelanstate)
          console.log("Selected langauge", selectedLanguage)
          console.log("Language", language)
          const welcomeMessage = "I am delighted to assist you with...";
          const translatedText = await translateEnglishToOther(welcomeMessage, selectedLanguage);
          setLanguage(selectedLanguage);
          const languageslist = {
            'en': 'English',
            'es': 'Spanish',
            'fr': 'French',
            'de': 'German',
            'it': 'Italian',
            'pt': 'Brazilian Portuguese',
            'ja': 'Japanese',
            'ko': 'Korean'
          };
          Lan = `${languageslist[selectedLanguage]}`
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: Lan, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
          ]);
          dblanguage = Lan;
          console.log("Language before updating in document db", dblanguage)
          await addDialog(Lan, false, user.name)
          await updatedb();
          if (!validateSessionId(newSessionId)) {
            console.error('Session ID is invalid. Action not allowed.');
            console.log("Session id", newSessionId)
            return;
          }
          console.log('Session ID is valid');
          console.log("username in handle select", user.name)
          //await addDialog(Lan, false, user.name)
          await addDialog(welcomeMessage, true);
          // await getConversationHistory()
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedText, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
          ]);
          const requestraise = "Make a Request"
          transrequestraise = await translateEnglishToOther(requestraise, selectedLanguage)

          const options = ["My Tickets", "Report an Issue", "Change Language", "Make a Request"];
          translatedTexts = await translateMultipleEnglishToOther(
            options,
            selectedLanguage
          );

          console.log("1stttt", translatedTexts["My Tickets"])
          setTranslatedOptions(translatedTexts);
          setShowAdditionalButtons(true);
          setAwaitingDescription(false)
          setShowTextfield(false);
        }
        if (menumain == true) {
          openKBarticle = []
          console.log("-----User pressed Main menu")
          setShowKBhelpList(false)
          setShowMainMenuExitButtons(false)
          setSelectedCard(false)
          setmenuafterticketdetails(false)
          setSelfDropdownVisible(false)
          setMyTicketsExtraButtonsVisible(false)
          setkbYesNo(false)
          setkbYesNo1(false)
          setNoKbButtonsVisible(false)
          setcreateIncidentsVisible(false)
          setAwaitingDescription(false)
          setShowTextfield(false);
          setTickets("")
          setShowKBarticleButtons(false)
          setShowSevenOptions(false)
          setShowSubcategories(false)
          setbuttonmenuafterticketdetails(false)
          datacount = 0;
          count = 0;
          setLanguage(selectedLanguage);
          const languageslist = {
            'en': 'English',
            'es': 'Spanish',
            'fr': 'French',
            'de': 'German',
            'it': 'Italian',
            'pt': 'Brazilian Portuguese',
            'ja': 'Japanese',
            'ko': 'Korean'
          };
          Lan = `${languageslist[selectedLanguage]}`
          if (changelanstate == true) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: Lan, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
          ]);
          changelanstate = false
          console.log("Language state in handleselectlanguage function", changelanstate)
          dblanguage = Lan;
          console.log("Language before updating in document db after user clicked main menu", dblanguage)
          await addDialog(Lan, false, user.name)
          await updatedb();
          }
          
          console.log("Data count so far when user selected mainmenu", datacount)
          console.log("count so far when user selected mainmenu", count)
          console.log("Inside if in handle select language - SetLangauge variable", selectedLanguage)
          console.log("Inside if in handle select language - Langauge variable", language)
          selectedLanguage = selectedLanguage ? selectedLanguage : language
          const welcomeMessage =
            "I'm delighted to assist you with...";
          const translatedText = await translateEnglishToOther(
            welcomeMessage,
            selectedLanguage
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedText, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
          ]);
          await addDialog(welcomeMessage, true);
          // await getConversationHistory()
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setLanguage(selectedLanguage);
          const requestraise = "Make a Request"
          transrequestraise = await translateEnglishToOther(requestraise, selectedLanguage)
          const options = ["My Tickets", "Report an Issue", "Change Language", "Make a Request"];
          translatedTexts = await translateMultipleEnglishToOther(
            options,
            selectedLanguage
          );
          console.log("Translated texts", translatedTexts)
          console.log("1stttt", translatedTexts["My Tickets"])
          setTranslatedOptions(translatedTexts);
          setShowAdditionalButtons(true);
          setAwaitingDescription(false)
          setShowTextfield(false);
          resolve(true)
        }
      } catch (error) {
        reject(false)
        console.error("Error in handleSelectLanguage function in chatbot.js file", error);
      }
    })
  };
// toggleSelfDropdown function is responsible for showing How many user affected list
  const toggleSelfDropdown = () => {
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      console.log("Toggling dropdown visibility");
      setSelfDropdownVisible(!selfDropdownVisible);
    }
    catch(error) {
      console.log("Error in toggleSelfDropdown function in chatbot.js file", error)
    }
  };

  // handleMainMenuClick function triggers whenever user presses Main Menu button
  const handleMainMenuClick = useCallback(async () => {
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      console.log("User clicked Main menuuuuu")
      const menumainclick = "Main Menu";
      const transmenumainclick =
        await translateEnglishToOther(
          menumainclick,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transmenumainclick, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
      ]);
      menumain = true
      var mainmenudialog = 'Selected "Main Menu"'
      await handleSelectLanguage(language);
      // Logic for Main Menu button
      addDialog(mainmenudialog, false, user.name)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setShowMainMenuExitButtons(false);
      setexitchat(false);
      setbacktotickets(false);
      setsearchwithtktnum(false)
      setShowMoreTicketDetailsButton(false)
      setchatliveagent(false)
      setTickets(false)
      //setShowAdditionalButtons(true);
    }
    catch(error){
      console.log("Error in handleMainMenuClick function in chatbot.js file", error)
    }
  }, [language]);

  
 // handleDropdownOptionClick function when triggered will provide IT services lists to the user
  const handleDropdownOptionClick = useCallback(
    async (option) => {
      try {
        if (!validateSessionId(newSessionId)) {
          console.error('Session ID is invalid. Action not allowed.');
          return;
        }
        setSelfDropdownVisible(false);
        console.log('Session ID is valid');
        addDialog(option, false, user.name)
        // await getConversationHistory();
        // await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: option, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
        ]);
        // Add the selected option to the chat window messages
        impactedUser = option;
        message = `Please select the Relevant IT service from the drop down`;
        const translatedMessage = await translateEnglishToOther(
          message,
          language
        );
        //setchatliveagent(true)
        addDialog(message, true)
        // await getConversationHistory();
        // await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
        ]);
        // Optionally, you can close the dropdown after selecting an option
        setSelectedOption(option);
        // setSelfDropdownVisible(false);
        setShowSevenOptions(true);
      }
      catch(error) {
        console.log("Error in handleDropdownOptionClick function in chatbot.js file", error)
      }
    },
    [language]
  );
// handleOtherButtonClick function will be triggered when user selected Other button when asked myself or others. This function will ask for the email address 
  async function handleOtherButtonClick() {
    try{
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    
    setcreateIncidentsVisible(false)
    const other = 'Selected "Others"';
    const buttonother = "Others";
    const transbuttonother = await translateEnglishToOther(
      buttonother,
      language
    );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transbuttonother, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
    ]);

    let mailmsg =
      "Please enter your mail address";
    transmailmsg = await translateEnglishToOther(
      mailmsg,
      language
    );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transmailmsg, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
    ]);
    addDialog(other, false, user.name)
    // await getConversationHistory();
    // await updateRedis(conversationHistory, newSessionId, token)
    await updatedb();
    console.log('Session ID is valid');
    setShowEmailInput(true);
    setcreateIncidentsVisible(false)
  }
  catch(error){
    console.log("Error in handleOtherButtonClick function in chatbot.js file", error)
  }
  }

  // handleEmailChange function when user types mail address
  function handleEmailChange(event) {
    try{
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log("EMail changeeeee")
    console.log('Session ID is valid');
    setUserEmail(event.target.value);
  }
  catch(error)
  {
    console.log("Error in handleEmailChange function in chatbot.js file", error)
  }
  }
// handleEmailSubmit function triggers when user Submits the email
  async function handleEmailSubmit() {
    try{
      console.log("Email submitttttt")
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    if (validateEmail(userEmail)) {
    
      otheruserID = userEmail;
      addDialog(userEmail, false, user.name)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setShowEmailInput(false);
      setcreateIncidentsVisible(false);
      setAwaitingDescription(false)
      setShowTextfield(false);
      message = `Please wait for a moment`;
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      addDialog(userEmail, false, user.name)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token);;
      userInfo = await userDetails(userEmail, token);
      console.log("Details", userInfo);
      if (userInfo[1] === "Incorrect") {
        if (userInfo[0] === 3) {
          let exhaustedmsg =
            "You’ve exceeded the limit for invalid email entries. Please check and try again later. Thank you!";
          const translatedMessagesub = await translateEnglishToOther(
            exhaustedmsg,
            language
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedMessagesub, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
          ]);
          addDialog(exhaustedmsg, true)
          setShowMainMenuExitButtons(true);
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
        } else {
          let exhaustedmsg = "Please enter correct email address.";
          const translatedMessagesub = await translateEnglishToOther(
            exhaustedmsg,
            language
          );

          addDialog(exhaustedmsg, true)
          // await getConversationHistory();
          // await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedMessagesub, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
          ]);
          setShowEmailInput(true);
        }
      } else {
        setShowEmailInput(false);
        setcreateIncidentsVisible(false);
        toggleSelfDropdown(); // Toggle the dropdown visibility
        message = "How many users are impacted, select from the drop down";
        const translatedmessage = await translateEnglishToOther(
          message,
          language
        );
        addDialog(message, true)
        // await getConversationHistory();
        // await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: translatedmessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
        ]);
      }
      // Continue with the next steps
    } else {
      const incorrectemail = "Please enter a valid email ID."
      addDialog(incorrectemail, false, user.name)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      const transmailinvaild = await translateEnglishToOther(
        incorrectemail,
        language
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transmailinvaild, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      setShowEmailInput(true);
    }
  }
  catch(error){
    console.log("Error in handleEmailSubmit function in chatbot.js file", error)
  }
  }
// validateEmail function will validate both structure of the email input(if the input has .com, @) also it will validate th
  const validateEmail = (email) => {
    try{
      console.log("validate emaillllll")
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    const maile = email;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: maile, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
    ]);
    setUserEmail("")
    setShowEmailInput(false);
    console.log('Session ID is valid');
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }
  catch(error){
    console.log("Error in validateEmail function in chatbot.js file", error)
  }
  
  };

  // handleSevenOptionClick function will provide Relevant Request Items for the user
  const handleSevenOptionClick = useCallback(
    async (service) => {
      try{
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      setShowSevenOptions(false);
      setShowApplicationList(false);
      setShowAdditionalButtons(false);
      console.log('Session ID is valid');
      const serviceseven = service;
      addDialog(service, false, user.name)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: service, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
      ]);
      message = `Please select the Relevant Request Item from the drop down`;
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      addDialog(message, true)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      selectedITservice = service;
      iTservice = ITServiceMapping[service];
      console.log("IT service number mapping", iTservice);
      setSelectedService(service);
      setSubcategories(ITServices[service]);
      setShowSubcategories(true);
    }
    catch(error){
      console.log("Error in handleSevenOptionClick function in chatbot.js file", error)
    }
    },
    [language]
  );
// handleSubcategoryClick function is responsible for showing the user a card that contains the details user selected so far while creating a ticket
  const handleSubcategoryClick = useCallback(
    async (subcategory) => {
      try{
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      addDialog(subcategory, false, user.name)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      console.log('Session ID is valid');
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: subcategory, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
      ]);
      selectedRequestItem = subcategory;
      requestItem = RequestedItemMapping[selectedITservice][selectedRequestItem];
      console.log("Request Item number mapping", requestItem);
      setShowSubcategories(false);
      console.log("Selected Subcategory:", subcategory);
      message = `Please find the details of your Ticket`;
      const translatedMessagesub = await translateEnglishToOther(
        message,
        language
      );
      addDialog(message, true)
      const detailsticket = {
        title: "Ticket Description:",
        itService: `1. IT Services : ${selectedITservice}`,
        requestItem: `2. Service Request: ${selectedRequestItem}`,
        summary: `3. Summary : ${summary}`,
        impactedUser: `4. Impacted User: ${impactedUser}`,
      }
      addDialog(detailsticket, true)
      await updatedb()
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessagesub, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      setSelectedCard(detailsticket);
      setShowAdditionalButtons(false);
    }
    catch (error){
      console.log("Error in handleSubcategoryClick function in chatbot.js file", error)
    }
    },
    [language]
  );
// handleSubmitTicket function will be triggered when user selects submit after user submits the ticket, this function will create ticket and show the ticket number to the user
  const handleSubmitTicket = useCallback(async () => {
    try{
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    setSelectedCard(null);
    const tktsubmit = "Submit";
    const transtktsubmit =
      await translateEnglishToOther(
        tktsubmit,
        language
      );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transtktsubmit, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
    ]);
    message = `Please wait for a moment`;
    const translatedMessage = await translateEnglishToOther(message, language);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
    ]);

    const response = await fetch(`${baseUrl}/api/ticketcreation?aa=bb`, {
      method: "POST",
      headers: {
        "authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        summary: summary,
        ITservice: iTservice,
        RequestItem: requestItem,
        IampactedUser: impactedUser,
        OtherMailID: userInfo.emailID,
        ClientID: userInfo.clientID,
        SiteID: userInfo.siteID,
        UserID: userInfo.userID,
      }),
    });
    if (!response.ok) {
      let exhaustedmsg = "Unfortunately unable to process the query at this moment. Please try after sometime.";
      const translatedMessagesub = await translateEnglishToOther(
        exhaustedmsg,
        language
      );
      addDialog(exhaustedmsg, true)
      // await getConversationHistory()
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessagesub, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      throw new Error("Network response was not ok");
    }
    else {
      csatstate = "createticket"
      const data = await response.json();
      console.log("Data from frontend", data);
      let exhaustedmsg = `I have created a Ticket with Ticket Number: ${data}`;
      const translatedMessagesub = await translateEnglishToOther(
        exhaustedmsg,
        language
      );
      const userselection = 'Selected "Submit Ticket"';
      addDialog(userselection, false, user.name)
      addDialog(exhaustedmsg, true)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessagesub, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      dbnormalticket = JSON.stringify(data)
      console.log("Created ticket before updating in document db", dbnormalticket)
      await updatedb();
      //setShowMainMenuExitButtons(true);
      //setShowMainMenuExitButtons(true)
      showFeedbackOptions(true);
      setFeedbackOptionsVisible(true);
      // Add your logic to handle submission
    }
  }
  catch(error){
    console.log("Error in handleSubmitTicket function in chatbot.js file", error)
  }
  }, [language]);

 // handleSelfButton function will be triggered when user selects Myself button when user asked to select Self or Others
  const handleSelfButton = useCallback(
    async (action) => {
      try{
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      setcreateIncidentsVisible(false)
      console.log('Session ID is valid');
      message = `Please wait for a moment`;
      const self = 'Selected "Myself"'
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      addDialog(self, false, user.name)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      const translatedmessageuser = await translateEnglishToOther(
        action,
        language
      );

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedmessageuser, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
        { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      userInfo = await userDetails(user.username, token);
      console.log("Details", userInfo);
      setcreateIncidentsVisible(false);
      message = "How many users are impacted, select from the drop down";
      const translatedmessage = await translateEnglishToOther(
        message,
        language
      );
      addDialog(message, true)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedmessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" }
      ]);
      toggleSelfDropdown();
    }
    catch(error){
      console.log("Error in handleSelfButton function in chatbot.js file", error)
    }
    },
    [language]
  );
// handleButtonClick function provides the functionality of My Tickets, Report an Issue, Change Language, Make a request
  const handleButtonClick = useCallback(
    async (option) => {
      try{
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      const myTicketsOptions = ["Last 5 Tickets", "Search with Ticket Number"];
      const translatedMyTicketsOptions =
        await translateMultipleEnglishToOther(myTicketsOptions, language);
      const myTicketsOptions1 = [
        "Last 5 Tickets",
        "Search with ticket number",
        "Show More Ticket Details",
        "Chat with live agent",
        "Main Menu",
        "End Chat",
        "Submit",
        "Send"
      ];
      var translatedtextsubmenu = await translateMultipleEnglishToOther(
        myTicketsOptions1,
        language
      );
      last5tkt = translatedtextsubmenu["Last 5 Tickets"];
      searchwithtktnumber = translatedtextsubmenu["Search with ticket number"];
      showmoretktdetails = translatedtextsubmenu["Show More Ticket Details"];
      liveagent = translatedtextsubmenu["Chat with live agent"];
      mainmenu = translatedtextsubmenu["Main Menu"];
      exitchat = translatedtextsubmenu["End Chat"];
      submitbutton = translatedtextsubmenu["Submit"]
      console.log('Session ID is valid inside handle button click');
      try {
        switch (option) {
          case "Report an Issue":
            setShowAdditionalButtons(false);
            var subemail = "Submit"
            transsubemail = await translateEnglishToOther(subemail, language)
            textarea = "Report an Issue";
            const queryraise = "Report an Issue";
            const transqueryraise =
              await translateEnglishToOther(
                queryraise,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transqueryraise, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
            ]);
            const normalmsg = "Kindly provide a description for the issue you encountered."
            const translatedMessage = await translateEnglishToOther(
              normalmsg,
              language
            );
            addDialog(queryraise, false, user.name)
            addDialog(normalmsg, true)
            // await getConversationHistory();
            // await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
            ]);
            setButtonsVisible(false);
            setAwaitingDescription(true);
            setShowTextfield(false);
            setRaiseRequestButtonVisible(false);
            setPendingOption(option);
            break;

          case "My Tickets":
            setShowAdditionalButtons(false);
            setButtonsVisible(false);
            textarea = 'Selected "My Tickets"';
            addDialog(textarea, false, user.name)
            console.log("text area", textarea);
            const usermytickets = "My Tickets";
            const transusermytickets = await translateEnglishToOther(
              usermytickets,
              language
            );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transusermytickets, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
            ]);

            const normalmytktmsg = "Please select one of the following options for your tickets"
            const myTicketsMessage = await translateEnglishToOther(
              normalmytktmsg,
              language
            );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: myTicketsMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
            ]);
            addDialog(normalmytktmsg, true)
            // await getConversationHistory();
            // await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setMyTicketsButtonTranslations(translatedMyTicketsOptions);
            setMyTicketsExtraButtonsVisible(true);
            setAwaitingDescription(false)
            setShowTextfield(false);
            break;

          case "Change Language":

            setShowAdditionalButtons(false);
            console.log("User selected Change language button")
            //await handleSelectLanguage(language)
            const lchange = "Change Language";
            const translchange =
              await translateEnglishToOther(
                lchange,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translchange, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
            ]);
            const changelan = "Please let me know your preferred language."
            const userchangelan = 'Selected "Change Language"'
            addDialog(userchangelan, false, user.name)
            addDialog(changelan, true)
            // await getConversationHistory();
            // await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            const handleChangeLanguage = async () => {
              changelanstate = true
              setLanguage(null);
              console.log("Triggered the handleChangelanguage function")
              console.log("Language state in handlechangelanguage function",changelanstate)// Reset language to show the language selector again
            };
            await handleChangeLanguage()
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                text: changelan,
                createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
                from: "bot",
              },
            ]);
            setAwaitingDescription(false);
            setShowTextfield(false);
            break;

          case "Last 5 Tickets":
            setMyTicketsExtraButtonsVisible(false);
            setShowMainMenuExitButtons(false)
            var choice = 'Selected "Last 5 tickets"'
            console.log("After selecting Last 5 Tickets");
            const last5tickets = "Last 5 Tickets";
            const translast5tickets =
              await translateEnglishToOther(
                last5tickets,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translast5tickets, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
            ]);

            const messagewait = `Please wait for a moment`;
            const translatedmessagewait = await translateEnglishToOther(
              messagewait,
              language
            );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedmessagewait, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
            ]);
            try {
              const response = await fetch(
                `${baseUrl}/api/mytickets?mail=${encodeURIComponent(user.username)}`, {
                headers: {
                  "authorization": `Bearer ${token}`,
                }
              }
              );
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              const data = await response.json();
              ticketdata = data;
              addDialog(choice, false, user.name)
              addDialog(ticketdata, true)
              // await getConversationHistory();
              // await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              console.log("Backend API response:", ticketdata);
              //var his = // await getConversationHistory();
              if (Array.isArray(data) && data.length > 0) {
                const moreTicketMessage = "Please find your recent tickets below. If you would like to see more tickets please"
                const transmoreTicketMessage = await translateEnglishToOther(
                  moreTicketMessage,
                  language
                );
                const hereclick = "click here"
                const transhereclick = await translateEnglishToOther(
                  hereclick,
                  language
                )
                const haloUrl = `${config.Halo_Ticket_URL}/portal/tickets?btn=60&viewid=0`;
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    text: (
                      <span>
                        {transmoreTicketMessage}{" "}
                        <a
                          href={haloUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {transhereclick}
                        </a>
                      </span>
                    ),
                    createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
                    from: "bot",
                  },
                ]);
                addDialog(moreTicketMessage, true)
                // await getConversationHistory()
                // await updateRedis(conversationHistory, newSessionId, token)
                await updatedb();
                setTickets(ticketdata);
              } else {
                setTickets([]);
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    text: "Couldn't find a ticket for your ID, Please click End Chat to exit the chat or return to the Main Menu.",
                    createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
                    from: "bot",
                  },
                ]);
                setShowMainMenuExitButtons(true);
              }
            } catch (error) {
              console.error("Error fetching tickets:", error);
              setTickets([]);
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  text: "Couldn't find a ticket for your ID, Please click End Chat to exit the chat or return to the Main Menu.",
                  createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
                  from: "bot"
                },
              ]);
              setShowMainMenuExitButtons(true);
            }
            setAwaitingDescription(false);
            setShowTextfield(false);
            break;

          case "Search with Ticket Number":
            setMyTicketsExtraButtonsVisible(false);
            setShowMainMenuExitButtons(false)
            const srchtktnum = "Search with Ticket Number";
            const transsrchtktnum =
              await translateEnglishToOther(
                srchtktnum,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transsrchtktnum, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
            ]);
            var txt = 'Selected "Search with ticket number"';
            addDialog(txt, false, user.name)
            agentgenesys = false
            const searchTicketNumberMessage = "Please enter your ticket number";
            const translatedSearchTicketNumberMessage =
              await translateEnglishToOther(
                searchTicketNumberMessage,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedSearchTicketNumberMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
            ]);
            addDialog(searchTicketNumberMessage, true)
            // await getConversationHistory();
            // await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setNoKbButtonsVisible(false);
            setButtonsVisible(false);
            setAwaitingDescription(true);
            setShowTextfield(false);
            setPendingOption(option);
            break;

          case "Make a Request":

            setShowAdditionalButtons(false);
            setNoKbButtonsVisible(false)
            setButtonsVisible(false);
            window.open(`${config.Halo_Ticket_URL}/portal/services?btn=95&itil_requesttype_id=3`, "_blank");
            // Replace with actual Halo portal URL
            const requestraise = "Make a Request";
            const transrequestraise =
              await translateEnglishToOther(
                requestraise,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transrequestraise, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
            ]);
            addDialog(requestraise, false, user.name)
            // await getConversationHistory();
            // await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setShowMainMenuExitButtons(true)
            break;

          default:
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                text: `Sorry, there was an error processing your request.  Please close your browser window and start a new chat session`,
                createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
                from: "bot"
              },
            ]);
            setAwaitingDescription(false);
            setShowTextfield(false);
            break;
        }
      } catch (error) {
        console.error("Error translating button click message:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: `Sorry, there was an error processing your request.  Please close your browser window and start a new chat session`,
            createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
            from: "bot"
          },
        ]);
      }
    }
    catch(error){
      console.log("Error in handleButtonClick function in chatbot.js file", error)
    }
    },
    [language, user.username, validateSessionId]
  );
  // handleSubmit function triggers whenever user types something and sends that input, in this function we will be having if conditions that identifies if the input is coming for live agent, Kb artcile search, user entering ticket number
  const handleSubmit = async (e) => {
    try{
    console.log("Entered handle submit", textarea)
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    e.preventDefault();
    if (agentgenesys === true) {
      console.log("Inside handle submit in agent genesys if condition")
      await handleagentuser(input);
    }
    else if (textarea === "Report an Issue") {
      console.log("Inside else condition of handle submit")
      if (textarea === "Report an Issue") {
        
        summary = input;
        setInput("");
        console.log("Inside Report an Issue", newSessionId);
        try {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: input, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
          ]);
          const response = await fetch(`${baseUrl}/api/awsLex?aa=bb`, {
            method: "POST",
            headers: {
              "authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              summary: summary,
              language: language,
              session: newSessionId
            }),
          });
          setInput('');
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          else {
            
            const data = await response.json();
              console.log("Response from backend after trigger lex ", data);
            setKBhelpList([])
            if (data === "FallbackIntent") {
              makerequest = true
              raiseaticketbutton = "Raise a Ticket"
              transraiseaticketbutton = await translateEnglishToOther(
                raiseaticketbutton,
                language
              )
              console.log("Button::::", transraiseaticketbutton)
              console.log("Input entered by user", input)
              // setMessages((prevMessages) => [
              //   ...prevMessages,
              //   { text: input, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
              // ]);
              const translatedMessage3 = await translateOtherToEnglish(
                input,
                language
              );
              addDialog(translatedMessage3, false, user.name)
              setInput("");
              setAwaitingDescription(false);
              setShowTextfield(false);
              // await getConversationHistory();
              // await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              message = "No relevant knowledge article found regarding your query";
              const translatedMessage = await translateEnglishToOther(
                message,
                language
              );
              var cliveagent = "Chat with Live Agent"
              transcliveagent = await translateEnglishToOther(
                cliveagent,
                language
              )
              addDialog(message, true)
              // await getConversationHistory();
              // await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
              ]);
              setNoKbButtonsVisible(true);
              const noKbOptions = ["Raise a Ticket", "Chat with Live Agent", "Make a Request"];
              const translatedNoKbTexts = await translateMultipleEnglishToOther(
                noKbOptions,
                language
              );
            }

            else {
              preSignedURL = data.preSignedURL;
              const validIntents = data.validIntents
              translatedKBArticles = await translateMultipleEnglishToOther(
                validIntents,
                language
              );
              setTranslatedKBArticleButtons(translatedKBArticles);
              console.log("Data from aws s3::", data)
              console.log("Data from aws s3 presigned::", preSignedURL)
              console.log("Data from aws s3 valid intents::", validIntents)
              console.log("Data from aws s3 valid intents first postion article", validIntents[0])
              // setMessages((prevMessages) => [
              //   ...prevMessages,
              //   { text: input, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
              // ]);
              const translatedMessage = await translateOtherToEnglish(
                input,
                language
              );
              console.log("message::", translatedMessage);
              addDialog(translatedMessage, false, user.name)
              // await getConversationHistory();
              // await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              setAwaitingDescription(false);
              setShowTextfield(false);
              var message = `Please wait for a moment`;
              const transMessage = await translateEnglishToOther(
                message,
                language
              );
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: transMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
              ]);

              const noKbOptions = ["Create incident", "Chat with Live Agent"];
              const translatedNoKbTexts = await translateMultipleEnglishToOther(
                noKbOptions,
                language
              );
              raiseaticketbutton = "Raise a Ticket"
              transraiseaticketbutton = await translateEnglishToOther(
                raiseaticketbutton,
                language
              )
              var cliveagent = "Chat with Live Agent"
              transcliveagent = await translateEnglishToOther(
                cliveagent,
                language
              )
              setShowKBarticleButtons(true)
              makerequest = true
              message = "Please review the Knowledge based articles and let me know if you found them helpful?";
              addDialog(message, true)
              // await getConversationHistory();
              // await updateRedis(conversationHistory, newSessionId, token);
              translatedReviewMessage = await translateEnglishToOther(
                reviewMessage,
                language
              );
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: translatedReviewMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
              ]);
              console.log(translatedReviewMessage);
              const noKbOptions1 = ["Yes", "No"];
              const translatedNoKbTexts1 = await translateMultipleEnglishToOther(
                noKbOptions1,
                language
              );
              setkbYesNoTranslations1(true);
              setkbYesNoTranslations(translatedNoKbTexts1);
              setkblink(true);
              setkbYesNo(true);
              setNoKbButtonsVisible(false);
              setShowAdditionalButtons(false);
              setShowKBarticleButtons(true);
            }
          }
        }

        catch (error) {
          console.error("Error sending message:", error);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: "Sorry, there was an error processing your request.  Please close your browser window and start a new chat session",
              createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
              from: "bot",
            },
          ]);
        }
      } else if (textarea === "My Tickets") {
        console.log("inside elseif", textarea);
        try {
          await getticketdetails();
        } catch (error) {
          console.error("Error fetching ticket details:", error);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: "Sorry, there was an error processing your request.  Please close your browser window and start a new chat session",
              createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }),
              from: "bot",
            },
          ]);
        }
      }
    }

    else if (agentgenesys === false) {
      console.log('Session ID is validdd');
      console.log("User is in text area", textarea);
      if (input.trim() && language) {
        addDialog(input, false, user.name)
        // await getConversationHistory();
        // await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: input, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
        ]);
        setInput("");
        setAwaitingDescription(true);
        setShowTextfield(false);
        await getticketdetails(input);
      }
    }
  }
  catch(error){
    console.log("Error in handleSubmit function in chatbot.js file", error)
    }
  };
 
  const handleExtraButtonClick = useCallback((extraOption) => {
    try{
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: `You clicked ${extraOption}`, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
    ]);
    setExtraButtonsVisible(false);
  }
  catch(error){
    console.log("Error in handleExtraButtonClick function in chatbot.js file", error)
  }
  }, [validateSessionId]);

  // handleNoKbButtonClick function will be triggered when user clicked on Raise a ticket button.
  const handleNoKbButtonClick = useCallback(
    async (action) => {
      try{
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      setNoKbButtonsVisible(false);
      setkblink(false);
      setkbYesNo(false);
      message = "Are you reporting the issue for yourself or for others.";
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      addDialog(message, true)
      // await getConversationHistory();
      // await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      const translatedMessageuser = await translateEnglishToOther(
        action,
        language
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessageuser, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" },
        { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" },
      ]);
      const createIncident = ["Myself", "Other"];
      const translatedcreateIncidentsVisible =
        await translateMultipleEnglishToOther(createIncident, language);
      setcreateIncidentsVisibleTranslations(translatedcreateIncidentsVisible);
      setcreateIncidentsVisible(true);
      setShowAdditionalButtons(false);
      setAwaitingDescription(false);
      setShowTextfield(false);
    }
    catch(error){
      console.log("Error in handleNoKbButtonClick function in chatbot.js file", error)
    }
    },
    [language, validateSessionId]
  );
// handleFeedbackClick function triggers after user gave rating, this function will ask is the user is interested to give feedback or not
  const handleFeedbackClick = async (feedback) => {
    try{
    setFeedbackOptionsVisible(false);
    var fdback = "Submit Feedback"
    transfdback = await translateEnglishToOther(fdback, language)
    console.log("Transalted Feedback msg", transfdback)
    console.log("Language inside handleconfirmationresponse function", language)
    console.log("User selected emoji:", feedback)
    var yesreply = 'Yes'
    transyesreply = await translateEnglishToOther(yesreply, language)
    var noreply = 'No'
    transnoreply = await translateEnglishToOther(noreply, language)

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: feedback, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" }
    ]);
    const feedbackMessage = `As you said we were ${feedback}, would you like to provide more details?`;
    const translatedFeedbackMessage = await translateEnglishToOther(feedbackMessage, language);

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: translatedFeedbackMessage, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" }
    ]);

    switch (csatstate) {
      case "agent":
        dbagentrating = feedback;
        addDialog(`Rating : ${feedback}`, false, user.name)
        addDialog(feedbackMessage, true)
        // await getConversationHistory()
        // await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        break;
      case "last5tickets":
        dblast5ticketsrating = feedback;
        console.log("Inside handlefeebackclick last 5 tickets switch case", dblast5ticketsrating)
        addDialog(`Rating : ${feedback}`, false, user.name)
        addDialog(feedbackMessage, true)
        // await getConversationHistory()
        // await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        break;
      case "searchwithticket":
        dbsearchwithticketrating = feedback;
        addDialog(`Rating : ${feedback}`, false, user.name)
        addDialog(feedbackMessage, true)
        // await getConversationHistory()
        // await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        break;
      case "kbarticle":
        console.log("KB ARTICLE ------")
        dbkbarticlerating = feedback;

        addDialog(`Rating : ${feedback}`, false, user.name)
        addDialog(feedbackMessage, true)
        // await getConversationHistory()
        // await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        break;
      case "createticket":
        console.log("CREATE TICKET ------")
        dbcreateticketrating = feedback;

        addDialog(`Rating : ${feedback}`, false, user.name)
        addDialog(feedbackMessage, true)
        // await getConversationHistory()
        // await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        break;

    }
    // addDialog(feedbackMessage, true)
    // // await getConversationHistory()
    // // await updateRedis(conversationHistory, newSessionId, token);
    await updatedb();
    setSelectedFeedback(feedback);

    // setShowTextfield(true);
    setShowConfirmation(true);
  }
  catch(error){
    console.log("Error in handleFeedbackClick function in chatbot.js file", error)
  }
  }
  // handleConfirmationResponse function will capture (yes/no) whether the user is interested in giving feedback based in the user's input this function will proceed accordingly
  const handleConfirmationResponse = async (res) => {
    try{
    if (res === "yes") {
      setShowTextfield(true);
      const replyyes = 'Yes'
      const transreplyyes = await translateEnglishToOther(replyyes, language)
      addDialog(replyyes, false, user.name);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transreplyyes, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" }
      ]);
      setShowConfirmation(false);


      // addDialog(fdback, false , user.name)
      // await updatedb()
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   { text: transfdback, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" }
      // ])

    } else {

      const replyno = 'No'
      const transreplyno = await translateEnglishToOther(replyno, language)
      addDialog(replyno, false, user.name);
      dbagentfeedback = "";
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transreplyno, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" }
      ]);
      setShowConfirmation(false);
      setShowMainMenuExitButtons(true);
    }
  }
  catch(error){
  console.log("Error in handleConfirmationResponse function in chatbot.js file", error)
}
  };

  // handleSubmitFeedback function will capture user's feeback
  const handleSubmitFeedback = async (e) => {
    try{
    e.preventDefault();
    if (detailedFeedback.trim()) {
      //showTextfield(false)
      console.log("User typed this :", detailedFeedback)
      const translatedFeedbackMessage = await translateOtherToEnglish(detailedFeedback, language);

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: detailedFeedback, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "user" }
      ]);
      switch (csatstate) {
        case "agent":
          console.log("AGENT IN HANDLESUBMITFEEDBACK------")
          csat = '';
          csatstate = '';
          dbagentfeedback = translatedFeedbackMessage
          addDialog(`Feedback : ${translatedFeedbackMessage}`, false, user.name)
          // await getConversationHistory()
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          //console.log("User typed thing translated to english :", dbfeedback)
          break;
        case "last5tickets":
          dblast5ticketsfeedback = translatedFeedbackMessage
          csatstate = '';
          addDialog(`Feedback : ${translatedFeedbackMessage}`, false, user.name)
          // await getConversationHistory()
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          //console.log("User typed thing translated to english :", dbfeedback)
          break;
        case "searchwithticket":
          dbsearchwithticketfeedback = translatedFeedbackMessage
          csatstate = '';
          addDialog(`Feedback : ${translatedFeedbackMessage}`, false, user.name)
          // await getConversationHistory()
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          //console.log("User typed thing translated to english :", dbfeedback)
          break;
        case "kbarticle":
          console.log("KB ARTICLE  IN HANDLESUBMITFEEDBACK------")
          csat = '';
          csatstate = '';
          dbkbarticlefeedback = translatedFeedbackMessage
          addDialog(`Feedback : ${translatedFeedbackMessage}`, false, user.name)
          // await getConversationHistory()
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          //console.log("User typed thing translated to english :", dbfeedback)
          break;
        case "createticket":
          dbcreateticketfeedback = translatedFeedbackMessage
          csat = '';
          csatstate = '';
          addDialog(`Feedback : ${translatedFeedbackMessage}`, false, user.name)
          // await getConversationHistory()
          // await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          //console.log("User typed thing translated to english :", dbfeedback)
          break;

      }
      setShowTextfield(false);
      const acknowledgemsg = "Thanks for your feedback! We appreciate your inputs.";
      const translatedacknowledgemsg = await translateEnglishToOther(acknowledgemsg, language);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedacknowledgemsg, createdAt: new Date().toLocaleTimeString([], { timeStyle: 'short' }), from: "bot" }
      ])
      addDialog(acknowledgemsg, true);
      await updatedb();


      // dbfeedback = translatedFeedbackMessage
      // addDialog(translatedFeedbackMessage, false, user.name)
      // // await getConversationHistory()
      // // await updateRedis(conversationHistory, newSessionId, token);

      // console.log("User typed thing translated to english :", dbfeedback)
      setDetailedFeedback('');
      setSelectedFeedback(false)
      setShowMainMenuExitButtons(true);
    }
  }
 catch(error){
  console.log("Error in handleSubmitFeedback function in chatbot.js file", error)
 }
  }

  //   alert('you have selected attachment icon');
  //   // setModalOpen(true);
  // };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     alert(`you selected: ${file.name}`);
  //     setModalOpen(false);
  //   }
  // };

  // const handleCloseModal = () => {
  //   setModalOpen(false);
  // };

  return (
    <div className="chatbot">
      <div className="chat-window">
        <div className="messages">
          {messages.map((msg, index) => {
            return msg.text === reviewMessage ||
              msg.text === translatedReviewMessage ? (
              <>
                {showKBarticleButtons &&
                  preSignedURL.map((button, buttonIndex) => (
                    <button
                      key={buttonIndex}
                      className="chatbot-button"
                      onClick={() => kbLink(button.url, button.intent)} // Call kburl with both URL and name
                    >
                      {translatedKBArticleButtons[button.intent]} {/* Display button name */}
                    </button>
                  ))}
                <Message
                  key={index}
                  text={msg.text}
                  from={msg.from}
                  createdAt={msg.createdAt}
                />
              </>
            ) : (
              <Message
                key={index}
                text={msg.text}
                from={msg.from}
                createdAt={msg.createdAt}
              />
            );
          })}

          {!language ? (
            <LanguageSelector onSelectLanguage={handleSelectLanguage} />
          ) : (
            <>
              <div>
                {showAdditionalButtons && (
                  <div className="additional-buttons">
                    <button onClick={() => handleButtonClick("My Tickets")}>
                      {translatedTexts["My Tickets"]}
                    </button>
                    <button onClick={() => handleButtonClick("Report an Issue")}>
                      {translatedTexts["Report an Issue"]}
                    </button>
                    <button onClick={() => handleButtonClick("Change Language")}>
                      {translatedTexts["Change Language"]}
                    </button>
                    <button onClick={() => handleButtonClick("Make a Request")}>
                      {translatedTexts["Make a Request"]}
                    </button>
                  </div>
                )}
              </div>
              {extraButtonsVisible && (
                <div className="extra-buttons">
                  <button
                    onClick={() => handleExtraButtonClick("Extra Option 1")}
                  >
                    Extra Option 1
                  </button>
                  <button
                    onClick={() => handleExtraButtonClick("Extra Option 2")}
                  >
                    Extra Option 2
                  </button>
                </div>
              )}

              {raiseRequestButtonVisible && (
                <div>
                  <button
                    variant="contained"
                    onClick={() => handleButtonClick("Make a Request")}
                  >
                    {transrequestraise}
                  </button>
                </div>
              )}

              {myTicketsExtraButtonsVisible && (
                <div className="my-tickets-buttons">
                  <button onClick={() => handleButtonClick("Last 5 Tickets")}>
                    {myTicketsButtonTranslations["Last 5 Tickets"] ||
                      "View Open Tickets"}
                  </button>
                  <button
                    onClick={() => handleButtonClick("Search with Ticket Number")}
                  >
                    {myTicketsButtonTranslations["Search with Ticket Number"] ||
                      "View Closed Tickets"}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {noKbButtonsVisible && (
                <div className="no-kb-buttons">
                  <button onClick={() => handleNoKbButtonClick("Raise a Ticket")}>
                    {transraiseaticketbutton}
                  </button>
                  <button onClick={() => handleAgent("Chat with Live Agent")}>
                    {transcliveagent}
                  </button>
                  <button onClick={() => handleButtonClick("Make a Request")}>
                    {transrequestraise}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}
              {createIncidentsVisible && (
                <div className="create-incident">
                  <button onClick={() => handleSelfButton("Myself")}>
                    {createIncidentsVisibleTranslations["Myself"] || "Myself"}
                  </button>
                  <button onClick={() => handleOtherButtonClick("Other")}>
                    {createIncidentsVisibleTranslations["Other"] || "Other"}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {showKBhelpList && (
                <div className="kb-yesno">
                  {KBhelpList.map((article, index) => (
                    <button
                      key={index}
                      onClick={() => kbclick(article)}
                    >
                      {article}
                    </button>
                  ))}
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {kbYesNO && (
                <div className="kb-yesno">
                  <button onClick={() => KBhelpListClick("YES")}>
                    {kbYesNOTranslations["Yes"] || "Yes"}
                  </button>
                  <button onClick={() => kbclick("NO")}>
                    {kbYesNOTranslations["No"] || "No"}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {kbYesNO1 && (
                <div className="kb-yesno1">
                  <button onClick={() => kbclick1("YES1")}>
                    {kbYesNOTranslations1["Yes"] || "Yes"}
                  </button>
                  <button onClick={() => kbclick1("NO1")}>
                    {kbYesNOTranslations1["No"] || "No"}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {feedbackOptionsVisible && (
                <div className="feedback-options">
                  <button
                    variant="contained"
                    className="emoji-button"
                    onClick={() => handleFeedbackClick("Excellent")}
                    title="Excellent"
                  >
                    <img
                      src={Excellent}
                      alt="Excellent"
                      className="feedback-smiley"
                    />
                  </button>
                  <button
                    variant="contained"
                    className="emoji-button"
                    onClick={() => handleFeedbackClick("Good")}
                    title="Good"
                  >
                    <img src={Good} alt="Good" className="feedback-smiley" />
                  </button>
                  <button
                    variant="contained"
                    className="emoji-button"
                    onClick={() => handleFeedbackClick("OK")}
                    title="OK"
                  >
                    <img src={OK} alt="OK" className="feedback-smiley" />
                  </button>
                  <button
                    variant="contained"
                    className="emoji-button"
                    onClick={() => handleFeedbackClick("Poor")}
                    title="Poor"
                  >
                    <img
                      src={Poor}
                      alt="Excellent"
                      className="feedback-smiley"
                    />
                  </button>
                </div>
              )}

              {showConfirmation && (
                <div className="confirmation-message">
                  <button onClick={() => handleConfirmationResponse("yes")}>{transyesreply}</button>
                  <button onClick={() => handleConfirmationResponse("no")}>{transnoreply}</button>
                </div>
              )}

              {/* Feedback input field */}
              {showTextfield && (
                <form onSubmit={handleSubmitFeedback} className={classes.inputArea}>
                  <TextField
                    className={classes.feedbackInput}
                    style={{width:'80%', maxWidth:'300px'}}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                    maxRows={6}
                    value={detailedFeedback}
                    onChange={(e) => setDetailedFeedback(e.target.value)}  // Update the state
                    // placeholder="Please provide more details about your feedback..."
                    disabled={!showTextfield}
                    fullWidth
                  />
                  <button variant="contained" color="primary" type="submit">
                    {transfdback}
                  </button>
                </form>
              )}

              {selfDropdownVisible && (
                <>
                  <div className="dropdown-list">
                    <div
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                        <li
                          style={{ padding: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDropdownOptionClick("Organisation Wide")
                          }
                        >
                          Organisation Wide
                        </li>
                        <li
                          style={{ padding: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDropdownOptionClick("Multiple Users Affected")
                          }
                        >
                          Multiple Users Affected
                        </li>
                        <li
                          style={{ padding: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDropdownOptionClick("Single Users Affected")
                          }
                        >
                          Single Users Affected
                        </li>
                        <button onClick={handleMainMenuClick}>{mainmenu}</button>
                      </ul>
                    </div>
                  </div>
                  <div className="button-group">
                  </div>
                </>
              )}
            </>
          )}
          {showEmailInput && (
            <div className="chat-message user">
              <div className="chat-bubble">
                <input
                  type="email"
                  placeholder={transmailmsg}
                  value={userEmail}
                  onChange={handleEmailChange}
                  className="email-input"
                />
                <button onClick={handleEmailSubmit} className="submit-button">
                  {transsubemail}
                </button>
              </div>
            </div>
          )}

          {buttonmenuafterticketdetails && (
            <div className="additional-buttons">
              <button onClick={handlebacktotickets}>{last5tkt}</button>
              <button onClick={handlesearchwithtktnum}>
                {searchwithtktnumber}
              </button>
              <button onClick={handleShowMoreTicketDetails}>
                {showmoretktdetails}
              </button>
              <button onClick={handleexit}>{exitchat}</button>
              <button onClick={handleMainMenuClick}>{mainmenu}</button>
              <button onClick={() => handleAgent("Chat with Live Agent")}>
                {liveagent}
              </button>
            </div>
          )}
          {menuafterticketdetails && (
            <>
              <TKTCard
                title={menuafterticketdetails.title}
                numbertkt={menuafterticketdetails.numbertkt}
                tktsummary={menuafterticketdetails.tktsummary}
                statetkt={menuafterticketdetails.statetkt}
              />
              <div className="card-buttons">
                <button onClick={handlebacktotickets}>{last5tkt}</button>
                <button onClick={handlesearchwithtktnum}>
                  {searchwithtktnumber}
                </button>
                <button onClick={handleShowMoreTicketDetails}>
                  {showmoretktdetails}
                </button>
                <button onClick={handleexit}>{exitchat}</button>
                <button onClick={handleMainMenuClick}>{mainmenu}</button>
                <button onClick={() => handleAgent("Chat with Live Agent")}>
                  {liveagent}
                </button>
              </div>
            </>
          )}

          {showSevenOptions && (
            <>
              <div className="dropdown-list">
                <ul>
                  <li
                    onClick={() =>
                      handleSevenOptionClick("Application & Software")
                    }
                  >
                    Application & Software
                  </li>
                  <li onClick={() => handleSevenOptionClick("Hardware")}>
                    Hardware
                  </li>
                  <li onClick={() => handleSevenOptionClick("I Don't Know")}>
                    I Don't Know
                  </li>
                  <li
                    onClick={() => handleSevenOptionClick("IT Security & Risk")}
                  >
                    IT Security & Risk
                  </li>
                  <li onClick={() => handleSevenOptionClick("Microsoft Office")}>
                    Microsoft Office
                  </li>
                  <li onClick={() => handleSevenOptionClick("Network")}>
                    Network
                  </li>
                  <li
                    onClick={() =>
                      handleSevenOptionClick("Reporting & Analytics")
                    }
                  >
                    Reporting & Analytics
                  </li>
                  <li onClick={() => handleSevenOptionClick("User Account")}>
                    User Account
                  </li>
                </ul>
              </div>
              <div className="button-group">
                <button
                  //disabled={true}
                  onClick={() => handleAgent("Chat with Live Agent")}
                >
                  {liveagent}
                </button>
                <button onClick={handleMainMenuClick}>{mainmenu}</button>
              </div>
            </>
          )}
          {showSubcategories && (
            <>
              <div className="dropdown-list subcategory-list">
                <ul>
                  {subcategories.map((subcategory, index) => (
                    <li
                      key={index}
                      onClick={() => handleSubcategoryClick(subcategory)}
                    >
                      {subcategory}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="button-group">
                <button
                  //disabled={true}
                  onClick={() => handleAgent("Chat with Live Agent")}
                >
                  {liveagent}
                </button>
                <button onClick={handleMainMenuClick}>{mainmenu}</button>

              </div>
            </>
          )}
          {selectedCard && (
            <>
              <Card
                title={selectedCard.title}
                itService={selectedCard.itService}
                requestItem={selectedCard.requestItem}
                summary={selectedCard.summary}
                impactedUser={selectedCard.impactedUser}
              // Conditionally add application name
              />
              <div className="card-buttons">
                <button onClick={() => handleSubmitTicket("Submit")}>
                  {submitbutton}
                </button>
                <button
                  //disabled={true}
                  onClick={() => handleAgent("Chat with Live Agent")}
                >
                  {liveagent}
                </button>
                <button onClick={handleMainMenuClick}>{mainmenu}</button>
              </div>
            </>
          )}
          {backtotickets && (
            <div className="Backt-To-Tickets">
              <button onClick={handlebacktotickets}>{last5tkt}</button>
            </div>
          )}
          {searchwithtktnum && (
            <div className="Search-With-Tkt-Num">
              <button onClick={handlesearchwithtktnum}>
                {searchwithtktnumber}
              </button>
            </div>
          )}
          {showMoreTicketDetailsButton && (
            <div className="Show-More-Ticket-Details">
              <button onClick={handleShowMoreTicketDetails}>
                {showmoretktdetails}
              </button>
            </div>
          )}

          {exitchatbutton && (
            <div className="Exit-Chat">
              <button onClick={handleexit}>{exitchat}</button>
            </div>
          )}
          {file && (
            <div>
              {/* <label for = "files" className="fileclass">Label Text</label> */}
              <input type="file" accept=".png, .jpeg, .gif" onChange={handleFileChange} ></input>
              {IsFile ?
            <button onClick={handleUpload} disabled={!file}>
              {transuploadfilebutton}
            </button> : null}
        </div>
        )
          }
          {chatliveagent && (
            <div className="Chat-Live- Agent">
              <button onClick={handleAgent}>{liveagent}</button>
            </div>
          )}
          {tickets.length > 0 && !showMoreTicketDetailsButton && (
            <div className="ticket-buttons">
              {tickets.map((ticket, index) => (
                <button key={index} onClick={() => handleTicketSelection(ticket)}>
                  {ticket} {/* Display the ticket number */}
                </button>
              ))}
              <button onClick={handleMainMenuClick}>{mainmenu}</button>
            </div>
          )}
          {showMainMenuExitButtons && (
            <div className="button-group">
              <button onClick={() => handleMainMenuClick("Main Menu")}>
                {mainmenu}
              </button>
              <button onClick={handleexit}>{exitchat}</button>
            </div>
          )}
          <div ref={messageEndRef} />
        </div>

        <div className={classes.inputArea}>
          {
            <form onSubmit={handleSubmit} className={classes.inputArea}>
              <TextField
                className={classes.input}
                variant="outlined"
                size="small"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={
                  awaitingDescription
                    ? "Type your description..."
                    : "Type your message..."
                }
                disabled={!(awaitingDescription)}
              />
              {/* <button type="button" onClick={handleAttachmentClick}>
                <FontAwesomeIcon icon={faPaperclip} />
              </button> */}
              <button variant="contained" color="primary" type="submit">
                Send
              </button>
            {IsVisible ? <button
              type="button"
              onClick={handleFileChange}
              style={{ background: 'none', border: 'none', marginLeft: '8px', color: 'blue' }}
            >
              <FontAwesomeIcon icon={faPaperclip} size="lg" />
            </button> : null}
            </form>
          }
        </div>
      </div>
    </div >
  );
}

export default Chatbot;
