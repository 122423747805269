import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ReportPage.css";

const ReportPage = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [message, setMessage] = useState("");
  const [minDate, setMinDate] = useState("2024-11-01"); // Hardcoded start date: 1st November 2024
  const [maxDate, setMaxDate] = useState("");
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'
  const navigate = useNavigate();
  const baseUrl = sessionStorage.getItem("swiss.api.baseUrl");
  var token = sessionStorage.getItem("swiss.api.access_token");

  useEffect(() => {
    const today = new Date();
    const max = today.toISOString().split("T")[0];
    setMaxDate(max); // Set the current date as the maximum allowed date
  }, []);

  const createreport = async () => {
    console.log("From date", fromDate);
    let data = JSON.stringify({
      dbfromdate: fromDate,
      dbtodate: toDate,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/report?aa=bb`,
      headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${token}`,
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
      console.log("reseponse from create report", response);
      console.log("reseponse.data from create report", response.data);
      return response.data;
    } catch (error) {
      console.log("Error in create report function in chatbot.js file", error);
    }
  };

  const handleGenerateReport = async () => {
    setMessage(""); // Reset message
    if (!fromDate || !toDate) {
      setMessageType("error");
      setMessage("Please select both 'From' and 'To' dates.");
      return;
    }

    setMessageType("info");
    setMessage("Generating report, please wait...");


    //const reporturl = await createreport();
    // if (reporturl) {
    //   var url2 =`${baseUrl}/api/report&token=${sessionStorage.getItem('swiss.api.access_token')}`
    //   window.open(reporturl, "_self");
    //   setMessageType("success");
    //   setMessage("Your report has been generated successfully!");
    // }
    // return reporturl;

    console.log("From date", fromDate);
    let data = JSON.stringify({
      dbfromdate: fromDate,
      dbtodate: toDate,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/report?aa=bb`,
      headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${token}`,
      },
      data: data,
      responseType: 'blob'
    };
    try {
      const response = await axios.request(config);
      console.log("reseponse from create report", response);
      console.log("reseponse.data from create report", response.data);

      if (response.status === 200) {
        // Handle the response as a Blob (Excel file)
        console.log(" in frontend status is 200:::::::")
        const blob = response.data;
        const url = window.URL.createObjectURL(blob); // Create a URL for the blob

        // Trigger file download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fromDate}_to_${toDate}_CVA_Report.xlsx`; // Specify the file name
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);

        setMessageType("success");
        setMessage("Your report has been generated successfully!");
      }
      //return response.data;
    } catch (error) {
      setMessageType("error");
      setMessage("Failed to generate the report. Please try again.");
    }
  };
  return (
    <div className="report-page">
      <h2>Select Date Range for Report</h2>
      <div className="date-input">
        <label htmlFor="fromDate">From:</label>
        <input
          type="date"
          id="fromDate"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          min={minDate} // Start date restricted to 1st November 2024
          max={toDate || maxDate} // Ensure `fromDate` does not exceed `toDate`
        />
      </div>
      <div className="date-input">
        <label htmlFor="toDate">To:</label>
        <input
          type="date"
          id="toDate"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          min={fromDate || minDate} // Ensure `toDate` does not precede `fromDate`
          max={maxDate} // Current date as the maximum allowed date
        />
      </div>
      <button onClick={handleGenerateReport}>Generate Report</button>
      <button onClick={() => navigate("/")}>Back to Chatbot</button>
      {message && <div className={`message-box ${messageType}`}>{message}</div>}
    </div>
  );
};

export default ReportPage;